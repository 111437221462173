<template>
  <div>
    <window-header></window-header>
    <nav-form :nuevo="false" :buscar="false" :eliminar="false"></nav-form>
    <section>
      <article style="height: 365px">
        <r-tabs ref="main-tabs" :buttons="['ERP', 'APP', 'Imágenes', 'Taquería', 'Informes', 'Web', 'Web 2']"
          style="top:0px; left:0px; height:365px;">
          <div class="tab">
            <field name="tarjeta_coordenadas_activa" widget="toggle" label="Acceso con tarjeta de coordenadas"
              :labels="{ checked: 'Activo', unchecked: 'Inactivo' }" :width="70" inline-label
              style="top:10px; right:360px;" />
            <field name="sonidos" widget="toggle" label="Sonidos" :labels="{ checked: 'Activo', unchecked: 'Inactivo' }"
              :width="70" inline-label style="top:40px; right:360px;" />

            <field name="almacen_defecto" widget="m2o" label="Almacén por defecto" width="200px" inline-label
              style="top:10px; right:10px;" />
            <field name="delegacion_defecto" widget="m2o" label="Delegación por defecto" width="200px" inline-label
              style="top:35px; right:10px;" />
            <field name="moneda_defecto" widget="m2o" label="Moneda por defecto" width="200px" inline-label
              style="top:60px; right:10px;" />
            <field name="tarifa_defecto" widget="select" :options="{
              '1': '1',
              '2': '2',
              '3': '3',
              '4': '4',
              '5': '5',
              '6': '6',
              '7': '7',
              '8': '8',
              '9': '9',
              Web: '10'
            }" label="Tarifa por defecto" help="Tarifa por defecto para nuevos clientes" width="200px" inline-label
              style="top:90px; right:10px;" />
            <field name="margen_cliente_defecto" type="number" label="Margen cliente" width="50px" inline-label
              style="top:120px; right:10px;" />

            <field name="recargo_equivalencia" type="number" label="Recargo de equivalencia" width="40px" inline-label
              style="top:90px; right:315px;" />

            <field name="tiempo_suspension" widget="int" sufix="min" default="20min" label="Tiempo de suspensión (min)"
              width="50px" inline-label style="top:150px; right:10px;" />
            <!--<field
              name="tiempo_sesion"
              widget="int"
              sufix="min"
              default="20min"
              label="Tiempo de sesión (min)"
              width="50px"
              inline-label
              style="top:145px; right:10px;"
            />-->

            <fieldset style="left: 10px; top: 33px; width: 155px; height: 166px">
              <legend>Atajos de teclado</legend>
              <div>
                <field name="atajo_guardar" label="Guardar: <b>Ctrl+</b>" widget="char" max="1"
                  style="right:10px;top:0px" inline-label width="20px" />
                <field name="atajo_anadir" label="Añadir: <b>Ctrl+</b>" widget="char" max="1"
                  style="right:10px;top:20px;" inline-label width="20px" />
                <field name="atajo_modificar" label="Modificar: <b>Ctrl+</b>" widget="char" max="1"
                  style="right:10px;top:40px;" inline-label width="20px" />
                <field name="atajo_minimizar" label="Minimizar: <b>Ctrl+</b>" widget="char" max="1"
                  style="right:10px;top:60px;" inline-label width="20px" />
                <field name="atajo_buscar" label="Buscar: <b>Ctrl+</b>" widget="char" max="1"
                  style="right:10px;top:80px;" inline-label width="20px" />
                <field name="atajo_cancelar" label="Cancelar: <b>Ctrl+</b>" widget="char" max="1"
                  style="right:10px;top:100px;" inline-label width="20px" />
                <field name="atajo_menu" label="Menú: <b>Ctrl+</b>" widget="char" max="1" style="right:10px;top:120px;"
                  inline-label width="20px" />
              </div>
            </fieldset>

            <fieldset style="left: 10px; top: 200px; width: 329px; height: 83px">
              <legend>Cifrar precio de coste</legend>
              <div>
                <field name="cifrar_reverso" widget="checkbox" reverse inline-label label="Reverso"
                  style="top:0px; left:5px;" />
                <field name="cifrar_sustitucion" widget="checkbox" reverse inline-label label="Sustitución"
                  style="top:25px; left:5px;" />

                <div v-show="parseInt(formData.cifrar_sustitucion)" name="cifrar"
                  style="position: absolute; top: 5px; left: 100px">
                  <div>
                    <div>
                      <field style="position:relative;" name="palabra_cifrado" :maxlength="10" width="140px"
                        inputStyle="font-family:monospace;letter-spacing: 5px;padding-left:3px;font-size:14px;" @input="
                          $set(
                            formData,
                            'palabra_cifrado',
                            formData.palabra_cifrado
                              .toUpperCase()
                              .replace(/[^0-9a-z]/gi, '')
                          )
                          " />
                    </div>
                  </div>
                  <div style="padding:3px;width:100%;font-family:monospace;letter-spacing: 5px;">
                    0123456789
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset style="left: 276px; top: 115px; width: 150px; height: 59px">
              <legend>Tipo EAN</legend>
              <div>
                <field name="tipo_ean" widget="radio" default="ean8" :options="{ EAN8: 'ean8', EAN13: 'ean13' }"
                  style="top:0px; left:15px;" />
              </div>
            </fieldset>
            <field name="tipo_cliente_defecto" widget="m2o" label="Tipo Cliente" width="200px" inline-label
              style="top:175px; right:10px;" labelProp="descripcion" />
            <field name="forma_pago_clientes_defecto" widget="m2o" label="F. Pago Cli." width="200px" inline-label
              style="top:200px; right:10px;" labelProp="descripcion" />
            <field name="forma_pago_proveedores_defecto" widget="m2o" label="F. Pago Prov." width="200px" inline-label
              style="top:225px; right:10px;" labelProp="descripcion" />
            <field name="forma_pago_representantes_defecto" widget="m2o" label="F. Pago Repr." width="200px"
              inline-label style="top:250px; right:10px;" labelProp="descripcion" />
            <field name="tipo_documento_defecto" widget="m2o" label="Tipo Documento" width="200px" inline-label
              style="top:275px; right:10px;" labelProp="nombre" />
          </div>
          <div class="tab">
            <!--
            <fieldset style="right: 15px; top: 40px; width: 272px; height: 105px">
              <div>
                <legend>Copias de seguridad automáticas</legend>
                <field name="tiempo_backup" label="Tiempo (días)" widget="int" min="1" style="right:10px;top:20px"
                  inline-label width="30px" />
              </div>
            </fieldset>-->

            <field name="importe_minimo_pedidos" type="number" label="Importe mínimo pedidos" width="80px" inline-label
              style="top:10px; left: 10px;" suffix=" €" />

            <b style="position:absolute;bottom:220px;left:5px">Reglas de envío</b>
            <field name="reglas_envios" widget="handsontable" :columns="[
              {
                name: 'importe',
                header: 'Importe (€)',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' }
              },
              {
                name: 'importe_gratis',
                header: 'Importe gratis (€)',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' }
              },
              {
                name: 'paises_ids',
                header: 'Países',
                type: 'm2m',
                primary: 'codigo',
                label: 'codigo'
              },
              {
                name: 'provincias_ids',
                header: 'Provincias',
                type: 'm2m',
                primary: 'codigo',
                label: 'codigo'
              },
            ]" :height="89" :minRows="1" style="bottom:130px; left:4px; width: 650px;" />

            <b style="position:absolute;bottom:92px;left:5px">Reglas de unidades mínimas</b>
            <field name="reglas_unidades_minimas" widget="handsontable" :columns="[
              {
                name: 'unidades_minimas',
                header: 'U.Mín.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.' }
              },
              {
                name: 'precio_min',
                header: 'Precio Mín.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' }
              },
              {
                name: 'precio_max',
                header: 'Precio Máx.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' }
              },
              {
                name: 'familia_id',
                header: 'Familia',
                type: 'm2o',
                primary: 'codigo',
                showCode: true
              },
              {
                name: 'seccion_id',
                header: 'Sección',
                type: 'm2o',
                primary: 'codigo',
                showCode: true
              },
              {
                name: 'familias_excluidas_ids',
                header: 'Familias excluidas',
                type: 'm2m',
                primary: 'codigo',
                label: 'codigo'
              },
            ]" :height="89" :minRows="1" style="bottom:5px; left:4px; width: 650px;" />
          </div>
          <div class="tab">
            <field name="mostrar_imagen_factura_proveedor" widget="toggle"
              label="Mostrar imagen del artículo en Facturas de Proveedor" :labels="{ checked: 'Sí', unchecked: 'No' }"
              :width="50" inline-label style="top:40px; right:10px;" />
            <field name="mostrar_imagen_factura_cliente" widget="toggle"
              label="Mostrar imagen del artículo en Facturas de Cliente" :labels="{ checked: 'Sí', unchecked: 'No' }"
              :width="50" inline-label style="top:65px; right:10px;" />
            <field name="mostrar_imagen_albaran_proveedor" widget="toggle"
              label="Mostrar imagen del artículo en Albaranes de Proveedor" :labels="{ checked: 'Sí', unchecked: 'No' }"
              :width="50" inline-label style="top:90px; right:10px;" />
            <field name="mostrar_imagen_albaran_cliente" widget="toggle"
              label="Mostrar imagen del artículo en Albaranes de Cliente" :labels="{ checked: 'Sí', unchecked: 'No' }"
              :width="50" inline-label style="top:115px; right:10px;" />
            <field name="mostrar_imagen_pedido_proveedor" widget="toggle"
              label="Mostrar imagen del artículo en Pedidos de Proveedor" :labels="{ checked: 'Sí', unchecked: 'No' }"
              :width="50" inline-label style="top:140px; right:10px;" />
            <field name="mostrar_imagen_pedido_cliente" widget="toggle"
              label="Mostrar imagen del artículo en Pedidos de Cliente" :labels="{ checked: 'Sí', unchecked: 'No' }"
              :width="50" inline-label style="top:165px; right:10px;" />
            <field name="mostrar_imagen_articulos_ocultos" widget="toggle"
              label="Mostrar imagen del artículo en Artículos activos por representante"
              :labels="{ checked: 'Sí', unchecked: 'No' }" :width="50" inline-label style="top:190px; right:10px;" />
          </div>
          <div class="tab">
            <field ref="reglas_taqueria" name="reglas_taqueria" widget="handsontable" :height="141" :minRows="5"
              style="top: 10px; left: 347px; width: 300px" :columns="[
                {
                  name: 'taqueria_ids',
                  header: 'Taquería',
                  help: 'Cód. Taqueria',
                  type: 'm2m',
                  primary: 'codigo',
                  image: 'thumbnail.imagen',
                  fields: ['precio_coste'],
                  label: 'codigo',
                  filter: () => ['seccion_id', '=', 'PCK'],
                },
                {
                  name: 'precio_minimo',
                  header: 'P. mín.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'precio_maximo',
                  header: 'P. máx',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
              ]" />
          </div>
          <div class="tab">
            <r-tabs :buttons="['Clientes', 'Proveedores']" style="top:0px; left:0px; height:322px;">
              <div class="tab">
                <field name="plantillas_documento_pedido_cliente" widget="m2m" label="Pedido Cliente" width="200px"
                  style="top:5px; right:435px;" :height="75" :filter="['model', '=', 'pedido_cliente']" />
                <field name="plantillas_documento_albaran_cliente" widget="m2m" label="Albarán Cliente" width="200px"
                  style="top:5px; right:225px;" :height="75" :filter="['model', '=', 'albaran_cliente']" />
                <field name="plantillas_documento_factura_cliente" widget="m2m" label="Factura Cliente" width="200px"
                  style="top:5px; right:15px;" :height="75" :filter="['model', '=', 'factura_cliente']" />

                <field name="plantillas_etiqueta_pedido_cliente" widget="m2m" label="Etiqueta Pedido cliente"
                  width="200px" style="top:100px; right:435px;" :height="75"
                  :filter="['model', '=', 'pedido_cliente']" />
                <field name="plantillas_etiqueta_albaran_cliente" widget="m2m" label="Etiqueta Albarán cliente"
                  width="200px" style="top:100px; right:225px;" :height="75"
                  :filter="['model', '=', 'albaran_cliente']" />
                <field name="plantillas_etiqueta_factura_cliente" widget="m2m" label="Etiqueta Factura cliente"
                  width="200px" style="top:100px; right:15px;" :height="75"
                  :filter="['model', '=', 'factura_cliente']" />

                <field name="plantilla_listado_pedido_cliente" widget="m2o" label="Listado Pedido cliente" width="200px"
                  style="top:195px; right:435px;" :filter="['model', '=', 'pedido_cliente']" />
                <field name="plantilla_listado_albaran_cliente" widget="m2o" label="Listado Albarán cliente"
                  width="200px" style="top:195px; right:225px;" :filter="['model', '=', 'albaran_cliente']" />
                <field name="plantilla_listado_factura_cliente" widget="m2o" label="Listado Factura cliente"
                  width="200px" style="top:195px; right:15px;" :filter="['model', '=', 'factura_cliente']" />

                <field name="plantilla_pedido_app" widget="m2o" label="Pedido APP" width="200px"
                  style="top:235px; right:435px;" :filter="['model', '=', 'pedido_cliente']" />

              </div>
              <div class="tab">
                <field name="plantillas_documento_pedido_proveedor" widget="m2m" label="Pedido proveedor" width="200px"
                  style="top:5px; right:435px;" :height="75" :filter="['model', '=', 'pedido_proveedor']" />
                <field name="plantillas_documento_albaran_proveedor" widget="m2m" label="Albarán proveedor" width="200px"
                  style="top:5px; right:225px;" :height="75" :filter="['model', '=', 'albaran_proveedor']" />
                <field name="plantillas_documento_factura_proveedor" widget="m2m" label="Factura proveedor" width="200px"
                  style="top:5px; right:15px;" :height="75" :filter="['model', '=', 'factura_proveedor']" />

                <field name="plantillas_etiqueta_pedido_proveedor" widget="m2m" label="Etiqueta Pedido proveedor"
                  width="200px" style="top:100px; right:435px;" :height="75"
                  :filter="['model', '=', 'pedido_proveedor']" />
                <field name="plantillas_etiqueta_albaran_proveedor" widget="m2m" label="Etiqueta Albarán proveedor"
                  width="200px" style="top:100px; right:225px;" :height="75"
                  :filter="['model', '=', 'albaran_proveedor']" />
                <field name="plantillas_etiqueta_factura_proveedor" widget="m2m" label="Etiqueta Factura proveedor"
                  width="200px" style="top:100px; right:15px;" :height="75"
                  :filter="['model', '=', 'factura_proveedor']" />

                <field name="plantilla_listado_pedido_proveedor" widget="m2o" label="Listado Pedido proveedor" width="200px"
                  style="top:195px; right:435px;" :filter="['model', '=', 'pedido_proveedor']" />
                <field name="plantilla_listado_albaran_proveedor" widget="m2o" label="Listado Albarán proveedor"
                  width="200px" style="top:195px; right:225px;" :filter="['model', '=', 'albaran_proveedor']" />
                <field name="plantilla_listado_factura_proveedor" widget="m2o" label="Listado Factura proveedor"
                  width="200px" style="top:195px; right:15px;" :filter="['model', '=', 'factura_proveedor']" />
              </div>
            </r-tabs>
          </div>
          <div class="tab">
            <field name="articulos_destacados" label="Artículos destacados" widget="m2m" dbAdapter="articulo" searchable
              width="300px" style="top: 9px; left: 9px" :height="255" imgProp="thumbnail.imagen" />
            <field name="articulos_destacados2" label="Nuestros artículos" widget="m2m" dbAdapter="articulo" searchable
              width="300px" style="top: 9px; right: 9px" :height="255" imgProp="thumbnail.imagen" />
          </div>
          <div class="tab">

            <field name="banner" widget="image" style="width: 170px; height: 310px; top: 4px; right: 6px" />
          </div>
        </r-tabs>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin],
  data: function () {
    return {
      title: "Configuración",
      dbAdapter: "configuracion",
      itemId: 1
    };
  },
  mounted() {
    var self = this;
    self.$on("save", function (data) {
      self.app.config = data;
    });
  }
};
</script>
<style scoped>
[name="cifrar"] {
  border: 1px solid #ccc;
}

fieldset {
  position: absolute;
}

fieldset>div {
  position: relative;
}
</style>
<template>
  <div>
    <window-header></window-header>
    <nav style="height:77px;">
      <button
        class="button button-plain"
        style="position:absolute;top:35px; right:10px;"
        @click="formData = {}; refreshTab()"
      >
        <fa-icon :icon="['far', 'trash-alt']" />
      </button>

      <div style="position:absolute;top:35px; right:890px;">Fecha</div>
      <field
        name="fecha_desde"
        type="date"
        label="Desde"
        inline-label
        width="100px"
        style="top:60px; right:830px;"
        @change="refreshTab"
      />
      <field
        name="fecha_hasta"
        type="date"
        placeholder="Hasta"
        width="100px"
        label="Hasta"
        inline-label
        style="top:85px; right:830px;"
        @change="refreshTab"
      />

      <div style="position:absolute;top:35px; right:690px;">Cliente</div>
      <field
        name="cliente_desde"
        label="Desde"
        placeholder="Desde"
        inline-label
        width="100px"
        style="top:60px; right:630px;"
        @change="refreshTab"
      />
      <field
        name="cliente_hasta"
        placeholder="Hasta"
        width="100px"
        label="Hasta"
        inline-label
        style="top:85px; right:630px;"
        @change="refreshTab"
      />

      <div style="position:absolute;top:35px; right:490px;">Importe</div>
      <field
        name="importe_desde"
        type="number"
        placeholder="Desde"
        label="Desde"
        inline-label
        width="100px"
        style="top:60px; right:430px;"
        @change="refreshTab"
      />
      <field
        name="importe_hasta"
        type="number"
        placeholder="Hasta"
        width="100px"
        label="Hasta"
        inline-label
        style="top:85px; right:430px;"
        @change="refreshTab"
      />
    </nav>
    <section>
      <article style="height:536px; width: 975px;">
        <r-tabs
          ref="main-tabs"
          :wt="formData.wt"
          style="top:0px; left:0px; height:535px;"
          :buttons="[
            '1. Pte. remesar',
            '2. Remesado',
            '3. Generado'
          ]"
          :styleButton="'width:33%;'"
          @active="onActiveTab"
        >
          <div class="tab">
            <field
              ref="documentos_pendientes"
              name="documentos_pendientes"
              widget="handsontable"
              :height="413"
              :width="950"
              :minRows="18"
              style="top:10px; left:4px; width: 953px;"
              :columns="[
                {name:'sel',header:'Sel.', widget: 'checkbox', help: 'Seleccionado'},
                {name:'fecha',type:'date2',header:'Fecha cobro',readOnly:true},
                {name:'cliente_id',header:'Cliente',readOnly:true,type:'m2o',primary:'codigo',label:'nombre_fiscal',showCode:true},
                {name:'concepto',header:'Concepto'},
                {name:'importe',header:'Importe',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}},
              ]"
            />
            <span style="position:absolute; top:430px; left:5px;">
              <button name="sel_all_docs" v-tooltip="'Seleccionar todos'" @click="sellAllDocs">
                <fa-icon :icon="['far', 'check-square']" />
              </button>
              <button
                name="dessel_all_docs"
                v-tooltip="'Desseleccionar todos'"
                @click="desselAllDocs"
              >
                <fa-icon :icon="['far', 'square']" />
              </button>
            </span>

            <div style="position:absolute;top: 430px; left: 100px;">
              Agrupar cheques:
              <br />
              <label>
                <input
                  style="height:0.8em"
                  type="radio"
                  name="agrupar"
                  @change="$set(formData,'agrupar','fechacobro')"
                  :checked="formData.agrupar=='fechacobro'"
                /> Por fecha de cobro
              </label>
              <br />
              <label>
                <input
                  style="height:0.8em"
                  type="radio"
                  name="agrupar"
                  @change="$set(formData,'agrupar','dia')"
                  :checked="formData.agrupar=='dia'"
                /> Para un día específico
                <field
                  v-if="formData.agrupar=='dia'"
                  style="position:relative;display: inline-block;"
                  name="fecha_remesa"
                  width="100px"
                  type="date"
                />
              </label>
            </div>

            <field
              name="banco"
              dbAdapter="banco_empresa"
              primary="id"
              imgProp="entidad.imagen"
              labelProp="cuenta"
              widget="m2o"
              placeholder="Banco"
              width="300px"
              label="Banco"
              style="top:435px; right:150px;"
            />

            <button
              class="button"
              style="position:absolute; top: 445px; right: 10px;"
              v-tooltip="'Generar cheques'"
              :class="{disabled:!docsSelected().length||!formData.banco||!formData.agrupar||(formData.agrupar=='dia'&&!formData.fecha_remesa)}"
              @click="generarCheques"
            >Generar cheques</button>
          </div>
          <div class="tab">
            <field
              ref="documentos_remesados"
              name="documentos_remesados"
              widget="handsontable"
              :height="476"
              :width="950"
              :minRows="21"
              style="top:10px; left:4px; width: 953px;"
              :columns="[
                {name:'fecha',type:'date2',header:'Fecha cobro',readOnly:true},
                {name:'fecha_remesado',type:'date2',header:'Fecha remesa',readOnly:true},
                {name:'cliente_id',header:'Cliente',readOnly:true,type:'m2o',primary:'codigo',label:'nombre_fiscal',showCode:true},
                {name:'concepto',header:'Concepto',readOnly:true},
                {name:'importe',header:'Importe',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}}
              ]"
            />
          </div>
          <div class="tab">
            <field
              ref="cheques"
              name="cheques"
              widget="handsontable"
              :height="276"
              :width="950"
              :minRows="12"
              style="top:10px; left:4px; width: 953px;"
              :columns="[
                {name:'fecha',type:'date2',header:'Fecha cobro',readOnly:true},
                {name:'importe',header:'Importe',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}},
                {name:'banco',header:'Banco',readOnly:true,type:'m2o',primary:'id',label:'cuenta',image:'entidad.imagen'},
                {name:'vencimientos',header:'Vencimientos',readOnly:true},
                {name:'doc',header:'Documento',readOnly:true,renderer:rendererButtonGenerado}
              ]"
            />
            <field
              ref="lineas_cheque"
              name="lineas_cheque"
              widget="handsontable"
              :height="180"
              :width="950"
              :minRows="7"
              style="top:300px; left:4px; width: 953px;"
              :columns="[
                {name:'fecha',type:'date2',header:'Fecha cobro',readOnly:true},
                {name:'cliente_id',header:'Cliente',readOnly:true,type:'m2o',primary:'codigo',label:'nombre_fiscal',showCode:true},
                {name:'concepto',header:'Concepto',readOnly:true},
                {name:'importe',header:'Importe',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}}
              ]"
            />
          </div>
        </r-tabs>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import Handsontable from "handsontable";
var floatOrZero = function(val) {
  return isFinite(parseFloat(val || 0.0)) ? parseFloat(val || 0.0) : 0.0;
};
export default {
  mixins: [WindowMixin, rFormMixin],
  data: function() {
    return {
      title: "Cobros Cheques banco",
      dbAdapter: "factura_cliente",
      primary: "id",
      mode: "new",
      showWt: false,
      original_lineas_cheque: [],
      activeTab: 0
      /*sequence: { name: "almacen" }*/
    };
  },
  methods: {
    generarCheques() {
      var self = this;
      var cheques = [];
      if (self.formData.agrupar == "fechacobro") {
        let objCheques = {};
        self.docsSelected().forEach(function(v) {
          objCheques[v.fecha] = objCheques[v.fecha] || {
            vencimientos: [],
            fecha: v.fecha,
            importe: 0,
            banco: self.formData.banco.id
          };
          objCheques[v.fecha].vencimientos.push({
            id: v.id,
            concepto: v.concepto,
            cuenta: v.cuenta,
            fecha_remesado: new Date().yyyymmdd(),
            _op: "put"
          });
          objCheques[v.fecha].importe += parseFloat(v.importe || 0);
        });
        cheques = Object.values(objCheques);
      } else {
        cheques.push({
          fecha: self.formData.fecha_remesa,
          importe: self
            .docsSelected()
            .map(a => a.importe)
            .reduce((p, c) => p + parseFloat(c || 0), 0),
          banco: self.formData.banco.id,
          vencimientos: self.docsSelected().map(v => {
            return {
              id: v.id,
              concepto: v.concepto,
              cuenta: v.cuenta,
              fecha_remesado: new Date().yyyymmdd(),
              _op: "put"
            };
          })
        });
      }
      var p = Promise.resolve();
      cheques.forEach(function(r) {
        p = p.then(
          _ => window.DB.post("cheque", {
            data: r
          })
        );
      });
      p.then(function() {
        self.app.toast("Cheques generados con éxito", "success");
        self.refreshTab();
      });
    },
    rendererButtonGenerado(
      instance,
      td,
      row,
      col,
      prop,
      value,
      cellProperties
    ) {
      var self = this;
      Handsontable.renderers.BaseRenderer.apply(this, arguments);
      let $td = window.$(td);
      $td.empty();
      if (instance.getSourceData()[row].id) {
        window
          .$(
            `<button class="button" style="height:1.3em;line-height:0.7;">Descargar</button>`
          )
          .appendTo($td)
          .click(function() {
            self.$utils.misc.downloadFile(
              window.DB.server +
                "cheque/?action=xmlCheque&id=" +
                instance.getSourceData()[row].id,
              "cheque.xml"
            );
          });
      }

      return $td[0];
    },
    refreshTab() {
      var self = this;
      switch (self.activeTab) {
        case 0:
          self.loadPtesRemesar();
          break;
        case 1:
          self.loadRemesados();
          break;
        case 2:
          self.loadRemesas();
          break;
      }
    },
    loadRemesados() {
      var self = this;
      let filter = [
        ["factura_id.formapago_id.tipo", "=", "cheque"],
        ["cheque", "IS NOT NULL"]
      ];
      if (self.formData.fecha_desde)
        filter.push(["fecha", ">=", self.formData.fecha_desde]);
      if (self.formData.fecha_hasta)
        filter.push(["fecha", "<=", self.formData.fecha_hasta]);
      if (self.formData.cliente_desde)
        filter.push([
          "factura_id.cliente_id",
          ">=",
          self.formData.cliente_desde
        ]);
      if (self.formData.cliente_hasta)
        filter.push([
          "factura_id.cliente_id",
          "<=",
          self.formData.cliente_hasta
        ]);
      if (self.formData.importe_desde)
        filter.push(["importe", ">=", self.formData.importe_desde]);
      if (self.formData.importe_hasta)
        filter.push(["importe", "<=", self.formData.importe_hasta]);
      window.DB.getList("vencimiento_cliente", {
        filter,
        depth: 12,
        fields: [
          "fecha",
          "importe",
          "cuenta",
          "concepto",
          {
            name: "factura_id",
            fields: [
              "ndoc",
              { name: "formapago_id", fields: ["tipo"] },
              {
                name: "cliente_id",
                fields: [
                  "nombre_fiscal",
                  { name: "pais_fiscal_id", fields: ["codigo", "abreviatura"] },
                  { name: "bancos", fields: ["cuenta", "predet"] }
                ]
              }
            ]
          }
        ]
      })
        .then(function(res) {
          let vencimientos = [];
          res.data.forEach(function(v) {
            let f = v.factura_id;
            vencimientos.push({
              fecha: v.fecha,
              importe: v.importe,
              cliente_id: f.cliente_id,
              concepto: v.concepto,
              cuenta: v.cuenta,
              pais: f.cliente_id.pais_fiscal_id.abreviatura
            });
          });
          self.$set(self.formData, "documentos_remesados", vencimientos);
        })
        .catch(function(res) {});
    },
    loadRemesas() {
      var self = this;
      let filter = [];
      if (self.formData.fecha_desde)
        filter.push(["fecha", ">=", self.formData.fecha_desde]);
      if (self.formData.fecha_hasta)
        filter.push(["fecha", "<=", self.formData.fecha_hasta]);
      if (self.formData.importe_desde)
        filter.push(["importe", ">=", self.formData.importe_desde]);
      if (self.formData.importe_hasta)
        filter.push(["importe", "<=", self.formData.importe_hasta]);
      window.DB.getList("cheque", {
        depth: 12,
        fields: [
          "fecha",
          "importe",
          {
            name: "banco",
            fields: ["cuenta", { name: "entidad", fields: ["imagen"] }]
          },
          {
            name: "vencimientos",
            fields: [
              "fecha",
              "importe",
              "cuenta",
              "concepto",
              {
                name: "factura_id",
                fields: [
                  "ndoc",
                  {
                    name: "cliente_id",
                    fields: [
                      "nombre_fiscal",
                      {
                        name: "pais_fiscal_id",
                        fields: ["codigo", "abreviatura"]
                      },
                      { name: "bancos", fields: ["cuenta", "predet"] }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      })
        .then(function(res) {
          self.original_lineas_cheque = res.data;
          self.$set(
            self.formData,
            "cheques",
            res.data.map(a => ({
              ...a,
              ...{ vencimientos: a.vencimientos.length }
            }))
          );
        })
        .catch(function(res) {});
    },
    loadPtesRemesar() {
      var self = this;
      let filter = [
        ["factura_id.formapago_id.tipo", "=", "cheque"],
        ["cheque", "IS NULL"]
      ];
      if (self.formData.fecha_desde)
        filter.push(["fecha", ">=", self.formData.fecha_desde]);
      if (self.formData.fecha_hasta)
        filter.push(["fecha", "<=", self.formData.fecha_hasta]);
      if (self.formData.cliente_desde)
        filter.push([
          "factura_id.cliente_id",
          ">=",
          self.formData.cliente_desde
        ]);
      if (self.formData.cliente_hasta)
        filter.push([
          "factura_id.cliente_id",
          "<=",
          self.formData.cliente_hasta
        ]);
      if (self.formData.importe_desde)
        filter.push(["importe", ">=", self.formData.importe_desde]);
      if (self.formData.importe_hasta)
        filter.push(["importe", "<=", self.formData.importe_hasta]);
      window.DB.getList("vencimiento_cliente", {
        filter,
        depth: 12,
        fields: [
          "fecha",
          "importe",
          {
            name: "factura_id",
            fields: [
              "ndoc",
              { name: "formapago_id", fields: ["tipo"] },
              {
                name: "cliente_id",
                fields: [
                  "nombre_fiscal",
                  { name: "pais_fiscal_id", fields: ["codigo", "abreviatura"] },
                  { name: "bancos", fields: ["cuenta", "predet"] }
                ]
              }
            ]
          }
        ]
      })
        .then(function(res) {
          let vencimientos = [];
          res.data.forEach(function(v) {
            let f = v.factura_id;
            let banco = f.cliente_id.bancos.find(b => parseInt(b.predet));
            let d = new Date(v.fecha);
            let now = new Date();
            let fecha = d < now ? now.yyyymmdd() : d.yyyymmdd();
            vencimientos.push({
              id: v.id,
              fecha: fecha,
              importe: v.importe,
              cliente_id: f.cliente_id,
              concepto: "Recibo Factura " + f.ndoc,
              cuenta: banco ? banco.cuenta : null,
              pais: f.cliente_id.pais_fiscal_id.abreviatura
            });
          });
          self.$set(self.formData, "documentos_pendientes", vencimientos);
        })
        .catch(function(res) {});
    },
    docsSelected() {
      var self = this;
      return self.formData.documentos_pendientes
        ? self.formData.documentos_pendientes.filter(doc => parseInt(doc.sel))
        : [];
    },
    onActiveTab(i) {
      var self = this;
      self.activeTab = i;
      self.refreshTab();
    },
    sellAllDocs() {
      var self = this;
      var h = self.$refs.documentos_pendientes.field.hotInstance;
      window.$.each(h.getSourceData(), function(i, o) {
        if (!h.isEmptyRow(i)) {
          h.setDataAtRowProp(i, "sel", 1, "sel_all"); //o.sel = 1;
        }
      });
      h.render();
    },
    desselAllDocs() {
      var self = this;
      var h = self.$refs.documentos_pendientes.field.hotInstance;
      window.$.each(h.getSourceData(), function(i, o) {
        if (!h.isEmptyRow(i)) {
          h.setDataAtRowProp(i, "sel", 0, "sel_all"); //o.sel = 1;
        }
      });
      h.render();
    },
    onChange(arrProps, callback) {
      this.$on("change", function(prop, source) {
        if (this.mode != "new" && this.mode != "edit") return;
        if (!arrProps.includes(prop)) return;
        callback(source);
      });
    }
  },
  mounted() {
    var self = this;

    // Al seleccionar un documento
    self.$refs.documentos_pendientes.field.hotInstance.addHook(
      "afterChange",
      function(changes, source) {
        if (source == "loadData") return;
        if (!changes) return;
        var h = this;

        changes.forEach(function(change, index) {
          var row = change[0];
          var prop = change[1];
          var oldVal = change[2];
          var newVal = change[3];
          if (prop == "sel") {
            //h.setDataAtRowProp(row, "sel", 0, "sel_all"); //o.sel = 1;
          }
        });
      }
    );

    self.$refs.cheques.field.hotInstance.addHook(
      "afterSelectionEndByProp",
      function(row, prop) {
        let h = this;
        if (h.getSourceData()[row].id) {
          self.$set(
            self.formData,
            "lineas_cheque",
            self.original_lineas_cheque[row].vencimientos.map(a => ({
              ...a,
              ...{
                cliente_id: a.factura_id.cliente_id,
                pais: a.factura_id.cliente_id.pais_fiscal_id.codigo
              }
            }))
          );
        } else {
          self.$set(self.formData, "lineas_cheque", []);
        }
      }
    );

    self.loadPtesRemesar();
  }
};
</script>
<template>
  <div class="tabs-root">
    <slot name="before" :rtabs="self">
      <div class="tabs-toolbar" ref="toolbar" :class="{ 'bg-wt': parseInt(wt) == 1, 'bg-wt2': parseInt(wt) == 2 }">
        <button v-for="(button, i) in buttons" :key="i" class="button tab-button" :class="{
          ...{ active: activeIndex == i },
          ...(typeof classButton == 'function'
            ? classButton(i)
            : classButton),
        }" :style="typeof styleButton == 'function' ? styleButton(i) : styleButton
          " @click="active(i)">
          {{ button }}
        </button>
      </div>
    </slot>
    <div class="tabs-animated-wrap">
      <div class="tabs" :style="'transform: translate3d(' + -100 * activeIndex + '%, 0px, 0px);'
        ">
        <slot :rtabs="self"></slot>
      </div>
    </div>
    <slot name="after" :rtabs="self"></slot>
  </div>
</template>
<script>
export default {
  inject: ["app"],
  data() {
    return {
      self: this,
      activeIndex: 0,
    };
  },
  props: {
    buttons: { type: Array },
    classButton: {},
    styleButton: {},
    wt: {},
  },
  methods: {
    active(idx) {
      var self = this;
      if (idx < 0) idx = 0;
      if (idx > this.buttons.length - 1) idx = this.buttons.length - 1;
      this.activeIndex = idx;
      self.$emit("active", idx);
      let $parent = window.$(this.$el).find(">.tabs-animated-wrap>.tabs>.tab").eq(idx);
      let $focusables = self.$utils.misc.getFocusables($parent);
      if (!$focusables.length) return;
      setTimeout(function () {
        if (idx != self.activeIndex) return;
        if ($parent.closest('.tab').length) return;
        $focusables[0].focus();
      }, 0);

      /*window
        .$(this.$el)
        .find(".tab")
        .removeClass("tab-active");
      window
        .$(this.$el)
        .find(".tab:nth-child(" + (this.activeIndex + 1) + ")")
        .addClass("tab-active");*/
      $parent.find(".hotTableComponent").each(function () {
        this.__vue__?.hotInstance?.scrollViewportTo(0, 0);
      });
    },
    nextTab() {
      let idx2 = this.activeIndex + 1;
      if (idx2 > this.buttons.length - 1) idx2 = 0;
      this.active(idx2);
    },
    prevTab() {
      let idx2 = this.activeIndex - 1;
      if (idx2 < 0) idx2 = this.buttons.length - 1;
      this.active(idx2);
    },
  },
  mounted() {
    this.active(0);
  },
};
</script>
<style>
.tabs-root {
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 5px;
  display: flex;
  flex-flow: column;
}

.tabs-animated-wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100%;
  border: 1px solid #ccc;
  border-top: none;
}

.tabs-animated-wrap>.tabs {
  display: flex;
  height: 100%;
  transition-duration: 0s;
}

.tab {
  width: 100%;
  display: block;
  flex-shrink: 0;
  position: relative;
}

.tabs-toolbar {
  width: 100%;
  background-color: #f7f7f8;
  padding: 5px;
  padding-bottom: 0;
  position: relative;
  border: 1px solid #ccc;
  display: flex;
  align-items: flex-end;
}

.tabs-toolbar .button {
  border-radius: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-bottom: none;
  position: relative;
}

.tabs-toolbar .button.active {
  background-color: white;
  border-bottom: 2px solid white;
  top: 1px;
}

.tab-button.disabled {
  pointer-events: none;
  color: #999 !important;
}

/*.tab {
  visibility: hidden;
}
.tab-active {
  visibility: visible;
}*/
.tabs-toolbar-wt {
  background: var(--color-wt);
}
</style>
<template>
  <div>
    <window-header></window-header>
    <nav>
      <button class="button button-fill" @click="save" :class="{disabled:!changed}">
        <fa-icon icon="save" />
        <span>{{$t('rform.save')}}</span>
      </button>
    </nav>
    <section>
      <article style="height: 300px; width:500px; display:flex;flex-direction: column">
        <div class="row" style="flex:1;">
          <Container
            group-name="colecciones"
            class="sortable-block"
            @drop="onDrop"
            :get-ghost-parent="getGhostParent"
            :get-child-payload="getChildPayload"
          >
            <Draggable :key="'coleccion-'+item.codigo" v-for="item in items">
              <div class="draggable-item">{{item.nombre}}</div>
            </Draggable>
          </Container>
        </div>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import { Container, Draggable } from "vue-smooth-dnd";
export default {
  components: { Container, Draggable },
  mixins: [WindowMixin, rTableMixin],
  data: function() {
    return {
      title: "Ordenar colecciones",
      dbAdapter: "coleccion",
      primary: "codigo",
      limit: -1,
      order: "posicion",
      orderDir: "ASC",
      changed: false
    };
  },
  methods: {
    save() {
      var self = this;
      var p = Promise.resolve();
      self.items.forEach(function(item, i) {
        p = p.then(
          _ => window.DB.put("coleccion", {
            itemId: item.codigo,
            data: {
              posicion: i
            }
          })
        );
      });
      p.then(function() {
        self.app.toast("Orden de las colecciones actualizado", "success");
        self.changed = false;
      }).catch(function(res) {
        window.console.log(res);
      });
    },
    onDrop(dragResult) {
      //this.items = 2;//applyDrag(this.items, dropResult);
      var arr = this.items;

      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      //const result = [...arr];
      let itemToAdd = payload;
      window.console.log(dragResult);
      if (removedIndex !== null) {
        itemToAdd = arr.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        arr.splice(addedIndex, 0, itemToAdd);
      }
      this.changed = true;
    },
    getGhostParent() {
      return document.body;
    },
    getChildPayload(index) {
      return this.items[index];
    }
  }
};
</script>
<style>
.sortable-block {
  width: 50%;
  overflow: auto;
  flex: 1;
}
.main-sortable-block {
  height: 100%;
  display: flex;
}
.draggable-item {
  padding: 5px;
  border: 1px solid #ccc;
  margin: 3px;
  background: white;
}
.row {
  padding: 4px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  overflow: auto;
}
</style>
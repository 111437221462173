<template>
  <div>
    <window-header></window-header>
    <nav style="justify-content: space-between;">
      <button
        class="button button-fill"
        :class="{disabled: activeTab==0}"
        @click="$refs['main-tabs'].prevTab()"
      >
        <fa-icon :icon="['fas', 'chevron-left']" />
        <span>{{$t('back')}}</span>
      </button>
      <button class="button button-fill" @click="$refs['main-tabs'].nextTab()" v-if="activeTab<1">
        <span>{{$t('next')}}</span>
        <fa-icon :icon="['fas', 'chevron-right']" />
      </button>
      <button
        v-if="activeTab==1&&clientesSelected().length"
        class="button button-fill"
        @click="finalizar"
      >
        <span>Guardar</span>
      </button>
    </nav>
    <section>
      <article style="height:395px;">
        <r-tabs
          ref="main-tabs"
          style="top:0px; left:0px; height:395px;"
          :buttons="[
            '1. Selección/Acotación',
            '2. Confirmación'
          ]"
          :styleButton="'width:50%;'"
          @active="onActiveTab"
        >
          <div class="tab">
            <filtro-seleccion-acotacion
              style="left: 5px; top: 80px;"
              referencia="actualizar_ivare"
              model="cliente"
              nombre="Actualizar IVA/RE"
            />
          </div>
          <div class="tab">
            <field
              ref="clientes"
              name="clientes"
              widget="handsontable"
              :height="345"
              :width="950"
              :minRows="15"
              style="top:10px; left:4px; width: 648px;"
              :columns="[
                {name:'sel',header:'Sel.', widget: 'checkbox', help: 'Seleccionado'},
                {name:'iva',header:'IVA',type:'numeric',numericFormat: { pattern: '0,0.00'}},
                {name:'re',header:'RE',type:'numeric',numericFormat: { pattern: '0,0.00'}},
                {name:'nombre_fiscal',header:'Nombre fiscal',readOnly:true},
                {name:'nombre_comercial',header:'Nombre comercial',readOnly:true},
                {name:'nif_cif',header:'NIF/CIF',readOnly:true},
                {name:'cp_fiscal',header:'CP',readOnly:true},
                {name:'poblacion_fiscal',header:'Población',readOnly:true},
                {name:'provincia_fiscal_id',header:'Provincia',readOnly:true,type:'m2o',primary:'codigo',label:'nombre',showCode:true},
                {name:'pais_fiscal_id',header:'País',readOnly:true,type:'m2o',primary:'codigo',label:'nombre',showCode:true},
              ]"
            />
          </div>
        </r-tabs>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import FiltroSeleccionAcotacion from "./../components/FiltroSeleccionAcotacion.vue";
var floatOrZero = function(val) {
  return isFinite(parseFloat(val || 0.0)) ? parseFloat(val || 0.0) : 0.0;
};
export default {
  mixins: [WindowMixin, rFormMixin],
  components: { FiltroSeleccionAcotacion },
  data: function() {
    return {
      title: "Actualizar IVA/RE",
      dbAdapter: "cliente",
      primary: "codigo",
      mode: "new",
      activeTab: 0
    };
  },
  methods: {
    finalizar() {
      var self = this;
      var p = Promise.resolve();
      self.formData.clientes.forEach(function(cliente) {
        if (parseInt(cliente.sel)) {
          p = p.then(
            _ => window.DB.put("cliente", {
              itemId: cliente.codigo,
              data: {
                iva: cliente.iva,
                re: cliente.re
              }
            })
          );
        }
      });
      p.then(function() {
        self.app.toast("Datos actualizados", "success");
      }).catch(function(e) {
        self.app.toast("Error al actualizar los datos", "error");
        window.console.log("Error: " + e);
      });
    },
    onActiveTab(i) {
      var self = this;
      self.activeTab = i;
      switch (i) {
        case 1:
          window.DB.getList("cliente", {
            filter: [],//TODO
            fields: [
              "iva",
              "re",
              "nombre_fiscal",
              "nombre_comercial",
              "nif_cif",
              "cp_fiscal",
              "poblacion_fiscal",
              { name: "provincia_fiscal_id", fields: ["nombre"] },
              { name: "pais_fiscal_id", fields: ["nombre"] }
            ]
          })
            .then(function(res) {
              self.$set(self.formData, "clientes", res.data);
            })
            .catch(function(res) {
              window.console.log(res);
            });
      }
    },
    clientesSelected() {
      var self = this;
      return self.formData.clientes
        ? self.formData.clientes.filter(doc => parseInt(doc.sel))
        : [];
    }
  }
};
</script>

<template>
  <div :class="{ 'form-loading': loading }">
    <window-header></window-header>
    <nav style="justify-content: space-between;">
      <r-progressbar :value="progressbar" v-if="progressbar"></r-progressbar>
      <template v-else>
        <button class="button button-fill" @click="abrirConfiguracion">
          <fa-icon icon="filter" /><span>Filtros</span>
        </button>
        <button class="button button-fill" @click="guardar">
          <fa-icon icon="save" /> <span>Guardar</span>
        </button>
      </template>
    </nav>
    <section>
      <article style="height: 536px; width: 975px">
        <div ref="hotTableComponent"></div>
      </article>
    </section>
  </div>
</template>

<script>
import WindowMixin from "./../components/WindowMixin.vue";
import Handsontable from "handsontable";

export default {
  mixins: [WindowMixin],
  data: function () {
    return {
      title: "Configuración web para los artículos",
      hotInstance: null,
      loading: false,
      progressbar: 0
    };
  },
  methods: {
    guardar() {
      let self = this;
      let data = self.hotInstance.getSourceData().filter(d => d.changed)
      if (!data.length) {
        self.app.toast("No se ha modificado ninguna línea", "error")
        return;
      }
      self.loading = true;
      let p = Promise.resolve();
      data.forEach((item, i) => {
        p = p.then(_ => {
          self.progressbar = Math.floor((i * 100) / data.length);
          return window.DB.put('articulo', {
            itemId: item.codigo, data: {
              activo_web: item.activo_web,
              nuevo_web: item.nuevo_web,
              promo_web: item.promo_web,
              descuento_web: item.descuento_web
            }
          })
        });
      })
      p
        .catch(e => self.app.toast("Error al actualizar los artículos", "error"))
        .then(_ => self.app.toast("Artículos actualizados", "success"))
        .finally(_ => {
          self.loading = false
          self.progressbar = 0
        })
    },
    abrirConfiguracion() {
      var self = this;
      self.app.openChildWindow("articulos_web_config", self.win, {
        backdrop: true,
        //model: self.model,
        props: {
          parent: self,
        },
      });
    },
  },
  mounted() {
    var self = this;

    self.filtro = null;

    self.actualizarArticulos = (forceVal) => {
      self.loading = true;
      let filter = [['activo', '=', 1]];
      if (self.filtro) filter.push(self.filtro);
      window.DB.getList('articulo', { filter, order: 'codigo', fields: [{ name: 'thumbnail', fields: ['imagen'] }, 'codigo', 'nombre', 'fecha_ultima_compra', 'activo_web', 'nuevo_web', 'promo_web', 'descuento_web'] }).then(resArt => {
        let data = resArt.data.map(a => ({ ...a, ...{ changed: typeof forceVal != 'undefined' } }));
        self.hotInstance.loadData(data);
      }).finally(_ => self.loading = false)
    }

    const rowHeaderWidth = 40;
    const checkboxWidth = 35;
    const codigoWidth = 100;
    const ucompraWidth = 70;
    const dtoWidth = 80;
    const descripcionWidth = Math.max(975 - rowHeaderWidth - 10 - codigoWidth - ucompraWidth - 3 * checkboxWidth - dtoWidth, 200);
    const colHeaders = ['Código', 'Descripción', 'U.Compra', 'Activo', 'Nuevo', 'Promo', 'Dto.'];
    const colWidths = checkboxWidth;
    let columns = [
      {
        data: 'codigo',
        readOnly: true,
        width: codigoWidth,
        renderer: function (hotInstance, td, row, column, prop, value, cellProperties) {
          Handsontable.renderers.TextRenderer.apply(this, arguments);
          let r = self.hotInstance?.toPhysicalRow(row) || row;
          if (hotInstance.getSourceDataAtRow(r).changed) td.style.backgroundColor = '#fff3bb';
        },
        columnSorting: {
          compareFunctionFactory: function compareFunctionFactory(sortOrder, columnMeta) {
            return function comparator(a, b) {
              //window.console.log(a, b, sortOrder, (a[order] > b[order] ? 1 : (a[order] < b[order] ? -1 : 0)) * (sortOrder == 'asc' ? 1 : -1))
              return (a > b ? 1 : (a < b ? -1 : 0)) * (sortOrder == 'asc' ? 1 : -1);
            };
          }
        }
      },
      { data: 'nombre', readOnly: true, width: descripcionWidth },
      { data: 'fecha_ultima_compra', type: 'date2', readOnly: true, width: ucompraWidth },
      { data: 'activo_web', type: 'checkbox', checkedTemplate: 1, uncheckedTemplate: 0, allowInvalid: false, allowEmpty: false, className: 'text-align-center' },
      { data: 'nuevo_web', type: 'checkbox', checkedTemplate: 1, uncheckedTemplate: 0, allowInvalid: false, allowEmpty: false, className: 'text-align-center' },
      { data: 'promo_web', type: 'checkbox', checkedTemplate: 1, uncheckedTemplate: 0, allowInvalid: false, allowEmpty: false, className: 'text-align-center' },
      { data: 'descuento_web', type: 'numeric', numericFormat: { pattern: '0,0.00' }, width: dtoWidth },
    ];

    self.hotInstance = new Handsontable(self.$refs.hotTableComponent, {
      licenseKey: 'non-commercial-and-evaluation',
      //autoRowSize: { syncLimit: 300 },
      dataSchema: { codigo: null, nombre: null },
      multiSelect: true,
      rowHeaders: true,
      width: 975,
      height: 535,
      columnSorting: true,
      sortIndicator: true,
      manualColumnResize: true,
      manualColumnMove: true,
      fillHandle: {
        //direction: 'vertical',
        autoInsertRow: false,
      },
      columns,
      colHeaders,
      colWidths,
      data: [],
      minRows: 0,
      minSpareRows: 0,
      beforeChange: (changes, source) => changes.forEach(c => changes.push([c[0], 'changed', null, 1])),
      rowHeights: 21,
      enterBeginsEditing: false,
      autoWrapCol: false,
      autoWrapRow: false,
      rowHeaderWidth,
      afterSelectionEnd: row => {
        row = self.hotInstance?.toPhysicalRow(row) || row;
        if (parseInt(self.app.config.mostrar_imagen_articulos_ocultos)) {
          let h = self.hotInstance;
          let articulo_id = h.getSourceData()[row].codigo;
          if (!articulo_id) return;
          let a = h.getSourceData()[row];
          if (self.win.children.length && self.win.children.some(ch => ch.window_name == 'popup_imagen')) {
            let popupImagen = self.win.children.find(ch => ch.window_name == 'popup_imagen');
            if (popupImagen.instance) popupImagen.instance.articulo = a;
            else popupImagen.opts.articulo = a;
          } else {
            self.app.openChildWindow('popup_imagen', self.win, {
              backdrop: false,
              resizable: true,
              articulo: a
            })
          }
        }
      },
    });
    self.actualizarArticulos();
  },
};
</script>

<style>
.text-align-center {
  text-align: center;
}
</style>
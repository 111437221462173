<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article style="height: 407px; width: 707px">
        <img :src="formData.thumbnail && typeof formData.thumbnail == 'object'
          ? formData.thumbnail.imagen
          : require('./../assets/noimage.png')
          " style="position: absolute;  width: 52px;  height: 52px;  top: 4px;  left: 6px;" />
        <field name="codigo" widget="char" searchable label="Código" help="Código del artículo"
          placeholder="Cód. artículo" width="100px" style="top: 9px; left: 68px" :readonly="mode == 'edit'"
          inputStyle="font-weight:bold;text-align:center;" @input="sequence.cancel = formData.codigo ? true : false" />
        <field name="nombre" widget="char" searchable label="Descripción" help="Descripción del artículo" required
          placeholder="Descripción del modelo" width="300px" style="top: 9px; left: 180px" />
        <field name="activo" value="true" widget="toggle" searchable
          :labels="{ checked: 'Activo', unchecked: 'Inactivo' }" :width="70" inline-label
          style="top: 22px; right: 5px" />

        <field name="modelo_id" widget="m2o" dbAdapter="modelo" label="Modelo" searchable required
          placeholder="Modelo del Articulo" help="Modelo del Articulo" width="134px" style="top: 9px; left: 492px"
          :fields="[
            'nombre',
            'unidad_medida_compra',
            'compra_metal_incluido',
            'unidad_medida_venta',
            'venta_metal_incluido',
            'recargo_equivalencia',
            'codigo_proveedor',
            'notas',
            'control_inventario',
            { name: 'thumbnail', fields: ['imagen'] },
            { name: 'thumbnail1', fields: ['imagen'] },
            { name: 'thumbnail2', fields: ['imagen'] },
            { name: 'thumbnail3', fields: ['imagen'] },
            { name: 'thumbnail4', fields: ['imagen'] },
            { name: 'tipo_comision_id', fields: ['descripcion'] },
            { name: 'marca_id', fields: ['nombre'] },
            { name: 'familia_id', fields: ['nombre'] },
            { name: 'seccion_id', fields: ['nombre'] },
            { name: 'proveedor_id', fields: ['nombre_comercial'] },
            {
              name: 'taqueria_ids',
              fields: ['nombre', { name: 'thumbnail', fields: ['imagen'] }],
            },
            {
              name: 'colecciones',
              fields: ['nombre', { name: 'imagen', fields: ['imagen'] }],
            },
            {
              name: 'atributos',
              fields: [
                { name: 'atributo_id', fields: ['nombre'] },
                'nombre',
              ],
            },
            {
              name: 'certificados',
              fields: [
                { name: 'naturaleza', fields: ['descripcion'] },
                { name: 'talla', fields: ['descripcion'] },
                { name: 'color', fields: ['descripcion'] },
                { name: 'pureza', fields: ['descripcion'] },
                'npiedras',
                'pesogema',
              ],
            },
          ]" @select="onSelectModelo" ref="modelo_id" />
        <r-tabs ref="main-tabs" :wt="wt" style="top: 55px; left: 0px; height: 350px" :buttons="[
          'General',
          'O.Datos',
          'Cert.',
          'Imagen',
          'Inventario',
          'U.Compra',
          'Web',
          'Conjuntos',
          'Rep.',
          'Notas',
        ]">
          <div class="tab">
            <fieldset style="
  position: absolute;
  left: 10px;
  top: 5px;
  width: 330px;
  height: 55px;
  ">
              <legend>Unidad de Medida en Compras:</legend>
              <field name="unidad_medida_compra" widget="radio" default="unidad"
                :options="{ Unidad: 'unidad', Peso: 'peso' }" inline-label style="top: 6px; left: 15px" />
              <field name="compra_metal_incluido" widget="checkbox" label="Compra con Metal Incluido" inline-label
                style="top: 6px; left: 147px" />
            </fieldset>
            <fieldset style="
  position: absolute;
  right: 10px;
  top: 5px;
  width: 330px;
  height: 55px;
  ">
              <legend>Unidad de Medida en Ventas:</legend>
              <field name="unidad_medida_venta" widget="radio" default="unidad"
                :options="{ Unidad: 'unidad', Peso: 'peso' }" inline-label style="top: 6px; left: 15px" />
              <field name="venta_metal_incluido" widget="checkbox" label="Venta con Metal Incluido" inline-label
                style="top: 6px; left: 147px" />
            </fieldset>
            <field name="familia_id" searchable widget="m2o" dbAdapter="familia" label="Familia" inline-label
              placeholder="Familia" width="250px" style="top: 65px; right: 353px" required :fields="[
                'unidad_medida_compra',
                'compra_metal_incluido',
                'unidad_medida_venta',
                'venta_metal_incluido',
                'recargo_equivalencia',
                'nombre',
                'control_inventario',
                { name: 'tipo_comision_id', fields: ['descripcion'] },
                { name: 'seccion_id', fields: ['nombre'] },
                { name: 'atributos', fields: ['nombre'] },
              ]" @select="onSelectFamilia" ref="familia_id" external-window="familia" />
            <field name="seccion_id" searchable widget="m2o" dbAdapter="seccion" label="Sección" inline-label required
              help="Sección a la que pertenece la Familia" width="270px" style="top: 90px; right: 353px" />

            <field name="talla" widget="float" type="number" searchable label="Talla" inline-label help="Talla"
              width="60px" style="top: 115px; left: 41px" />

            <field name="recargo_equivalencia" inline-label widget="toggle" :labels="{ checked: 'Sí', unchecked: 'No' }"
              label="R. Equiv." :width="50" style="top: 115px; left: 235px" />

            <fieldset style="
  position: absolute;
  right: 10px;
  bottom: 35px;
  width: 330px;
  height: 205px;
  ">
              <legend>Atributos:</legend>
              <field name="atributos" widget="handsontable" searchable :height="180" :minRows="7"
                style="top: 3px; left: 10px; width: 310px" :htSettings="htSettingsAtributos" :columns="[
                  {
                    name: 'atributo_id',
                    header: 'Atributo',
                    type: 'm2o',
                    label: 'nombre',
                  },
                  {
                    name: 'id',
                    header: 'Valor',
                    type: 'm2o',
                    label: 'nombre',
                    model: 'valor_atributo',
                    filter: (value, row, prop) =>
                      value && value['atributo_id']
                        ? ['atributo_id', '=', value['atributo_id']]
                        : [],
                  },
                ]" />
            </fieldset>

            <field name="liquidacion" inline-label widget="toggle" :labels="{ checked: 'Sí', unchecked: 'No' }"
              label="Liquidación" :width="50" style="bottom: 7px; right: 200px" />

            <div style="
  position: absolute;
  bottom: 7px;
  right: 100px;
  padding: 1px;
  width: 90px;
  text-align: center;
  border: 1px solid #ccc;
  min-height: 1.5em;
  font-size: 1em;
  ">
              {{ pCosteCifradoSustitucion }}
            </div>
            <div style="
  position: absolute;
  bottom: 7px;
  right: 10px;
  padding: 1px;
  width: 85px;
  text-align: center;
  border: 1px solid #ccc;
  min-height: 1.5em;
  font-size: 1em;
  ">
              {{ pCosteCifradoReverso }}
            </div>

            <fieldset style="
  position: absolute;
  left: 11px;
  bottom: 10px;
  width: 330px;
  height: 158px;
  ">
              <legend>Tarifas:</legend>
              <field name="tarifa1" widget="float" type="number" searchable label="1" inline-label
                placeholder="Tarifa 1" help="Tarifa 1" width="75px" dec="2" suffix=" €" />
              <input name="ptarifa1" placeholder="%" width="60px" style="
  position: absolute;
  top: 8px;
  left: 105px;
  font-size: 0.7em;
  width: 50px;
  " v-tooltip="'Beneficio con respecto al precio de coste'" :value="ptarifa(1)" @change="onChangePTarifa" :readonly="mode == 'view' || mode == 'empty' || mode == 'search'
    " :style="{ color: parseFloat(ptarifa(1)) < 0 ? 'red' : 'black' }" />
              <field name="tarifa2" widget="float" type="number" searchable label="2" inline-label
                placeholder="Tarifa 2" help="Tarifa 2" width="75px" style="top: 33px; left: 10px" dec="2" suffix=" €" />
              <input name="ptarifa2" placeholder="%" width="60px" style="
  position: absolute;
  top: 35px;
  left: 105px;
  font-size: 0.7em;
  width: 50px;
  " v-tooltip="'Beneficio con respecto al precio de coste'" :value="ptarifa(2)" @change="onChangePTarifa" :readonly="mode == 'view' || mode == 'empty' || mode == 'search'
    " :style="{ color: parseFloat(ptarifa(2)) < 0 ? 'red' : 'black' }" />
              <field name="tarifa3" widget="float" type="number" searchable label="3" inline-label
                placeholder="Tarifa 3" help="Tarifa 3" width="75px" style="top: 60px; left: 10px" dec="2" suffix=" €" />
              <input name="ptarifa3" placeholder="%" width="60px" style="
  position: absolute;
  top: 62px;
  left: 105px;
  font-size: 0.7em;
  width: 50px;
  " v-tooltip="'Beneficio con respecto al precio de coste'" :value="ptarifa(3)" @change="onChangePTarifa" :readonly="mode == 'view' || mode == 'empty' || mode == 'search'
    " :style="{ color: parseFloat(ptarifa(3)) < 0 ? 'red' : 'black' }" />
              <field name="tarifa4" widget="float" type="number" searchable label="4" inline-label
                placeholder="Tarifa 4" help="Tarifa 4" width="75px" style="top: 87px; left: 10px" dec="2" suffix=" €" />
              <input name="ptarifa4" placeholder="%" width="60px" style="
  position: absolute;
  top: 89px;
  left: 105px;
  font-size: 0.7em;
  width: 50px;
  " v-tooltip="'Beneficio con respecto al precio de coste'" :value="ptarifa(4)" @change="onChangePTarifa" :readonly="mode == 'view' || mode == 'empty' || mode == 'search'
    " :style="{ color: parseFloat(ptarifa(4)) < 0 ? 'red' : 'black' }" />
              <field name="tarifa5" widget="float" type="number" searchable label="5" inline-label
                placeholder="Tarifa 5" help="Tarifa 5" width="75px" style="top: 114px; left: 10px" dec="2"
                suffix=" €" />
              <input name="ptarifa5" placeholder="%" width="60px" style="
  position: absolute;
  top: 116px;
  left: 105px;
  font-size: 0.7em;
  width: 50px;
  " v-tooltip="'Beneficio con respecto al precio de coste'" :value="ptarifa(5)" @change="onChangePTarifa" :readonly="mode == 'view' || mode == 'empty' || mode == 'search'
    " :style="{ color: parseFloat(ptarifa(5)) < 0 ? 'red' : 'black' }" />
              <field name="tarifa6" widget="float" type="number" searchable label="6" inline-label
                placeholder="Tarifa 6" help="Tarifa 6" width="75px" style="top: 6px; right: 60px" dec="2" suffix=" €" />
              <input name="ptarifa6" placeholder="%" width="60px" style="
  position: absolute;
  top: 8px;
  right: 5px;
  font-size: 0.7em;
  width: 50px;
  " v-tooltip="'Beneficio con respecto al precio de coste'" :value="ptarifa(6)" @change="onChangePTarifa" :readonly="mode == 'view' || mode == 'empty' || mode == 'search'
    " :style="{ color: parseFloat(ptarifa(6)) < 0 ? 'red' : 'black' }" />
              <field name="tarifa7" widget="float" type="number" searchable label="7" inline-label
                placeholder="Tarifa 7" help="Tarifa 7" width="75px" style="top: 33px; right: 60px" dec="2"
                suffix=" €" />
              <input name="ptarifa7" placeholder="%" width="60px" style="
  position: absolute;
  top: 35px;
  right: 5px;
  font-size: 0.7em;
  width: 50px;
  " v-tooltip="'Beneficio con respecto al precio de coste'" :value="ptarifa(7)" @change="onChangePTarifa" :readonly="mode == 'view' || mode == 'empty' || mode == 'search'
    " :style="{ color: parseFloat(ptarifa(7)) < 0 ? 'red' : 'black' }" />
              <field name="tarifa8" widget="float" type="number" searchable label="8" inline-label
                placeholder="Tarifa 8" help="Tarifa 8" width="75px" style="top: 60px; right: 60px" dec="2"
                suffix=" €" />
              <input name="ptarifa8" placeholder="%" width="60px" style="
  position: absolute;
  top: 62px;
  right: 5px;
  font-size: 0.7em;
  width: 50px;
  " v-tooltip="'Beneficio con respecto al precio de coste'" :value="ptarifa(8)" @change="onChangePTarifa" :readonly="mode == 'view' || mode == 'empty' || mode == 'search'
    " :style="{ color: parseFloat(ptarifa(8)) < 0 ? 'red' : 'black' }" />
              <field name="tarifa9" widget="float" type="number" searchable label="9" inline-label
                placeholder="Tarifa 9" help="Tarifa 9" width="75px" style="top: 87px; right: 60px" dec="2"
                suffix=" €" />
              <input name="ptarifa9" placeholder="%" width="60px" style="
  position: absolute;
  top: 89px;
  right: 5px;
  font-size: 0.7em;
  width: 50px;
  " v-tooltip="'Beneficio con respecto al precio de coste'" :value="ptarifa(9)" @change="onChangePTarifa" :readonly="mode == 'view' || mode == 'empty' || mode == 'search'
    " :style="{ color: parseFloat(ptarifa(9)) < 0 ? 'red' : 'black' }" />
              <field name="tarifa10" widget="float" type="number" searchable label="Web" inline-label placeholder="Web"
                help="Tarifa 10" width="75px" style="top: 114px; right: 60px" dec="2" suffix=" €" />
              <input name="ptarifa10" placeholder="%" width="60px" style="
  position: absolute;
  top: 116px;
  right: 5px;
  font-size: 0.7em;
  width: 50px;
  " v-tooltip="'Beneficio con respecto al precio de coste'" :value="ptarifa(10)" @change="onChangePTarifa" :readonly="mode == 'view' || mode == 'empty' || mode == 'search'
    " :style="{
      color: parseFloat(ptarifa(10)) < 0 ? 'red' : 'black',
    }" />
            </fieldset>
          </div>
          <div class="tab">
            <field name="ean13" widget="char" inline-label searchable label="Cód.Barras" help="Cód.Barras"
              placeholder="Cód. Articulo" width="250px" style="top: 20px; right: 350px" />
            <div class="field aux" name="cifrado_reverso" searchable :readonly="true" widget="char" inline-label
              label="REV" width="250px" style="bottom: 10px; right: 350px" />
            <div class="field aux" name="cifrado_sustitucion" searchable :readonly="true" widget="char" inline-label
              label="SUS" width="250px" style="bottom: 10px; right: 350px" />
            <field name="marca_id" widget="m2o" dbAdapter="marca" searchable label="Marca" inline-label width="250px"
              style="top: 50px; right: 350px" />
            <field name="medida" widget="char" searchable label="Medida" inline-label width="250px"
              style="top: 80px; right: 350px" />

            <field name="proveedor_id" searchable widget="m2o" dbAdapter="proveedor" label="Proveedor"
              labelProp="nombre_comercial" inline-label width="250px" style="top: 110px; right: 350px" />
            <field name="codigo_proveedor" widget="int" searchable label="Ref. Proveedor" inline-label placeholder="0"
              width="250px" style="top: 140px; right: 350px" />
            <field name="tipo_comision_id" widget="m2o" dbAdapter="comision" labelProp="descripcion"
              label="Tipo Comision" inline-label width="250px" style="top: 170px; right: 350px" />

            <field name="colecciones" label="Colecciones" widget="m2m" dbAdapter="coleccion" searchable width="320px"
              style="top: 10px; right: 15px" :height="75" imgProp="imagen.imagen" />
            <field name="categorias" label="Categorías" widget="m2m" dbAdapter="categoria" searchable width="320px"
              style="top: 105px; right: 15px" :height="75" imgProp="imagen.imagen" />
            <field name="taqueria_ids" searchable widget="m2m" dbAdapter="articulo" label="Taquería"
              :filter="['seccion_id', '=', 'PCK']" position-label="block" help="Taquería" width="320px"
              style="top: 200px; right: 15px" :height="75" imgProp="thumbnail.imagen" />

          </div>
          <div class="tab">
            <field name="certificados" widget="handsontable" :height="258" :width="680" :minRows="11"
              style="top: 10px; left: 10px" customprop="certificados_customprop" :columns="[
                {
                  name: 'naturaleza',
                  header: 'Naturaleza',
                  type: 'm2o',
                  label: 'descripcion',
                  primary: 'codigo',
                  showCode: true,
                },
                {
                  name: 'talla',
                  header: 'Talla',
                  type: 'm2o',
                  label: 'descripcion',
                  primary: 'codigo',
                },
                {
                  name: 'npiedras',
                  header: 'N. Piedras',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.' },
                },
                {
                  name: 'pesogema',
                  header: 'Peso Gema',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                  sufix: ' ct',
                },
                {
                  name: 'color',
                  header: 'Color',
                  type: 'm2o',
                  label: 'descripcion',
                  primary: 'codigo',
                },
                {
                  name: 'pureza',
                  header: 'Pureza',
                  type: 'm2o',
                  label: 'descripcion',
                  primary: 'codigo',
                },
              ]" />
            <field name="material" searchable label="Material" inline-label help="Material" width="120px"
              style="bottom: 10px; left: 10px" />
            <field name="peso" widget="float" type="number" searchable label="Peso" inline-label help="Peso"
              width="80px" style="bottom: 10px; left: 190px" suffix=" gr" />
          </div>
          <div class="tab">
            <field name="thumbnail" widget="m2o_img" dbAdapter="imagen_articulo" searchable
              style="width: 190px; height: 190px; top: 45px; left: 20px" />
            <field name="thumbnail1" widget="m2o_img" dbAdapter="imagen_articulo" searchable
              style="width: 90px; height: 90px; top: 39px; left: 328px" />
            <field name="thumbnail2" widget="m2o_img" dbAdapter="imagen_articulo" searchable
              style="width: 90px; height: 90px; top: 39px; left: 470px" />
            <field name="thumbnail3" widget="m2o_img" dbAdapter="imagen_articulo" searchable
              style="width: 90px; height: 90px; top: 153px; left: 328px" />
            <field name="thumbnail4" widget="m2o_img" dbAdapter="imagen_articulo" searchable
              style="width: 90px; height: 90px; top: 153px; left: 470px" />
          </div>
          <div class="tab">
            <field name="inventario" widget="handsontable" :height="285" :width="480" :minRows="12"
              style="top: 10px; left: 4px; height: 100px" :readonly="true" :columns="[
                {
                  name: 'almacen_id',
                  header: 'Almacen',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true,
                },
                {
                  name: 'unidad',
                  header: 'Unidad',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
              ]" :fields="['wt']" />
            <fieldset style="
  position: absolute;
  right: 10px;
  top: 10px;
  width: 190px;
  height: 60px;
  ">
              <legend>Control Inventario</legend>
              <field name="control_inventario" widget="radio" default="input"
                :options="{ Unidad: 'unidad', Peso: 'peso', Ambos: 'ambos' }" style="top: 6px; left: 15px" />
            </fieldset>
          </div>
          <div class="tab">
            <field name="fecha_ultima_compra" widget="input" type="date" :readonly="true" label="Fecha Ultima Compra"
              inline-label help="Fecha Ultima Compra / Fabricación" placeholder="F.U.C." width="100px"
              style="top: 15px; right: 379px" inputStyle="text-align: right;" />
            <field name="precio_coste_hechura_divisa" widget="float" type="number" dec="2" :readonly="true"
              label="Prec. Coste Hechura en Divisa" inline-label help="Precio Coste Hechura en Divisa" placeholder
              width="100px" style="top: 45px; right: 379px" />
            <field name="divisa" widget="char" :readonly="true" label inline-label placeholder width="50px"
              style="top: 45px; right: 326px" />
            <field name="precio_coste_hechura_euros" widget="float" type="number" :readonly="true"
              label="Prec. Coste Hechura en Euros" inline-label help="Precio Coste Hechura en Euros" placeholder
              width="100px" style="top: 75px; right: 379px" dec="2" suffix=" €" />
            <field name="codigo_metal" widget="m2o" dbAdapter="metal" labelProp="descripcion" label="Metal" inline-label
              :readonly="true" width="160px" style="top: 105px; right: 379px" />
            <field name="precio_metal" widget="float" type="number" :readonly="true" label="Precio Metal (Fino)"
              inline-label help="Precio Metal (Fino)" width="100px" style="top: 135px; right: 379px" dec="4"
              suffix=" €" />
            <field name="hechura_contraste_otros_costes" widget="float" type="number" :readonly="true"
              label="Prec. Hech.+Contr.+O.Costes" inline-label help="Precio Hechura + Contraste + Otros Costes"
              width="100px" style="top: 165px; right: 379px" dec="2" suffix=" €" />
            <field name="coste_unidad_venta" widget="float" type="number" :readonly="true"
              label="Prec. Coste a unidad de Venta" inline-label help="Precio de Coste a unidad de Venta" placeholder
              width="100px" style="top: 195px; right: 379px" dec="2" suffix=" €" />
            <field name="margen_ultima_compra" widget="float" type="number" dec="2" :readonly="true"
              label="% Margen Ultima Compra" inline-label help="Margen Ultima Compra" placeholder="0.00%" width="100px"
              style="top: 225px; right: 379px" suffix=" %" />
            <field name="merma" widget="float" type="number" dec="2" :readonly="true" label="% Merma" inline-label
              help="% Merma" placeholder="0.00%" width="100px" style="top: 255px; right: 379px" suffix=" %" />
            <field name="taqueria_precio" widget="float" type="number" :readonly="true" label="Taquería" inline-label
              placeholder="0.00" width="100px" style="top: 285px; right: 379px" dec="2" suffix=" €" />
            <field name="peso_unitario_fino" widget="float" type="number" :readonly="true" label="Peso Unitario (fino)"
              inline-label help="Peso Unitario (Fino)" placeholder width="100px" style="top: 15px; right: 30px" dec="3"
              suffix=" gr" />
            <field name="precio_coste" widget="float" type="number" :readonly="true" label="Precio Coste" inline-label
              help="Precio Coste Hechura en Divisa" placeholder width="100px" style="top: 45px; right: 30px" dec="2"
              suffix=" €" />
            <field name="costes_fijos" widget="float" type="number" :readonly="true" label="Costes Fijos" inline-label
              help="Costes Fijos" placeholder width="100px" style="top: 75px; right: 30px" dec="2" suffix=" €" />
            <field name="costes_adicionales" widget="float" type="number" :readonly="true" label="Costes Adicionales"
              inline-label help="Costes Adicionales" placeholder width="100px" style="top: 105px; right: 30px" dec="2"
              suffix=" €" />
            <!--<field name="pmp_inicial" widget="char" :readonly="true" label="P.M.P. Inicial" inline-label help="P.M.P. Inicial" placeholder="" width="100px" style="top:165px; right:30px;"/>-->
            <field name="pmp" widget="float" type="number" :readonly="true" label="P.M.P." inline-label help="P.M.P."
              width="100px" style="top: 155px; right: 30px" dec="2" suffix=" €" />
            <field name="fecha_ultima_pmp" widget="input" type="date" :readonly="true" label="Fecha Ultima P.M.P."
              inline-label help="Fecha Ultima P.M.P." width="100px" style="top: 185px; right: 30px"
              inputStyle="text-align: right;" />
            <field name="peso_merma" widget="float" type="number" :readonly="true" label="Peso Merma (Fino)"
              inline-label help="Peso Merma (Fino)" placeholder width="100px" style="top: 215px; right: 30px" dec="3"
              suffix=" gr" />
            <field name="peso_unitario" widget="float" type="number" :readonly="true" label="Peso Unitario (Aleacion)"
              inline-label help="Peso Unitario (Aleacion)" placeholder width="100px" style="top: 245px; right: 30px"
              dec="3" suffix=" gr" />
            <field name="fecha_ultima_venta" widget="input" type="date" :readonly="true" label="F.U.V." inline-label
              help="Fecha Ultima Venta" placeholder width="100px" style="top: 270px; right: 30px"
              inputStyle="text-align: right;" />
          </div>
          <div class="tab">
            <fieldset style="position: relative; left: 10px; top: 10px; width: 230px; height: 62px; ">
              <legend>Prioridad APP</legend>
              <field name="prioridad" widget="radio" default="0"
                :options="{ '0': '0', '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', }" inline-label
                style="top: 6px; left: 15px" />
            </fieldset>
            <fieldset style="position: relative; left: 10px; top: 17px; width: 230px; height: 62px;">
              <legend>Prioridad Web</legend>
              <field name="prioridad_web" widget="radio" default="0"
                :options="{ '0': '0', '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', }" inline-label
                style="top: 6px; left: 15px" />
            </fieldset>

            <field name="articulos_relacionados_ids" label="Artículos relacionados" widget="m2m" dbAdapter="articulo"
              searchable width="300px" style="top: 6px; left: 254px" :height="122" imgProp="thumbnail.imagen" />

            <field name="activo_web" widget="toggle" label="Activo" :labels="{ checked: 'Sí', unchecked: 'No' }"
              :width="50" inline-label style="top:20px; right: 15px;" />
            <field name="nuevo_web" widget="toggle" label="Nuevo" :labels="{ checked: 'Sí', unchecked: 'No' }"
              :width="50" inline-label style="top:45px; right: 15px;" />
            <field name="promo_web" widget="toggle" label="Promo" :labels="{ checked: 'Sí', unchecked: 'No' }"
              :width="50" inline-label style="top:70px; right: 15px;" />
            <field name="descuento_web" help="Descuento aplicable a la web" widget="input" type="number" inline-label
              label="Descuento" placeholder="0.00 %" inputalign="center" dec="2" width="55px"
              style="top: 100px; right: 15px" />



            <field name="palabras_clave" help="Separadas por coma" label="Palabras clave" width="335px"
              style="top: 145px; left: 10px" />
            <!--<field name="publicar_productos_web" widget="toggle" ontext="Si" offtext="No" label="Publicar Productos en la Web" width="70" inline-label style="top:107px; left:24px;"/>
  <field name="tarifa_web" widget="char" label="T Web" inline-label width="25px" style="top:107px; left:290px;"/>-->

            <field name="traducciones" widget="handsontable" searchable :height="110" :width="673" :minRows="4"
              style="bottom: 10px; left: 10px;" :htSettings="htSettingsAtributos" :columns="[
                {
                  name: 'idioma',
                  header: 'Idioma',
                  help: 'Español (ES), Inglés (EN), Portugués (PT), Francés (FR)',
                  type: 'select',
                  options: ['ES', 'EN', 'PT', 'FR'],
                },
                {
                  name: 'nombre',
                  header: 'Nombre',
                },
              ]" />
          </div>
          <div class="tab">
            <button class="button" style="position: absolute; top: 17px; left: 66px; width: 32px;"
              v-tooltip="'Eliminar conjunto'" :class="{ disabled: selectedConjunto == null }"
              @click="onClickEliminarConjunto">-</button>
            <button class="button" style="position: absolute; top: 17px; left: 104px; width: 32px;"
              v-tooltip="'Crear conjunto'" :class="{ disabled: mode != 'new' && mode != 'edit' }"
              @click="onClickCrearConjunto">+</button>

            <field name="conjuntos" ref="conjuntos" widget="handsontable" :height="246" :width="130" :minRows="10"
              style="top: 52px; left: 10px" :readonly="true" :htSettings="htSettingsConjuntos"
              :fields="[{ name: 'articulos', fields: ['codigo', 'ean13', 'nombre', { name: 'familia_id', fields: ['codigo', 'nombre'] }, { name: 'seccion_id', fields: ['codigo', 'nombre'] }] }]"
              :columns="[
                {
                  name: 'codigo',
                  header: 'Conjunto',
                }
              ]" />
            <field v-if="selectedConjunto != null" ref="articulos_conjuntos"
              :name="selectedConjunto == null ? '_' : 'conjuntos.' + selectedConjunto + '.articulos'"
              widget="handsontable" :height="287" :width="550" :minRows="12" style="top: 10px; left: 150px"
              :readonly="selectedConjunto == null || !formData.conjuntos[selectedConjunto]"
              :htSettings="htSettingsArticulosConjuntos" :columns="[
                {
                  name: 'thumbnail',
                  header: ' ',
                  type: 'm2o',
                  model: 'imagen_articulo',
                  primary: 'codigo',
                  image: 'imagen',
                  width: 32,
                  readOnly: true
                },
                {
                  name: 'ean13',
                  header: '|||||||||',
                },
                {
                  name: 'codigo',
                  header: 'Código',
                },
                {
                  name: 'nombre',
                  header: 'Descripción',
                  readOnly: true,
                },
                {
                  name: 'familia_id',
                  header: 'Familia',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true,
                  readOnly: true,
                  model: 'familia'
                },
                {
                  name: 'seccion_id',
                  header: 'Sección',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true,
                  readOnly: true,
                  model: 'seccion'
                },
              ]" />
          </div>
          <div class="tab">
            <field ref="representantes" name="representantes_ocultos2" widget="handsontable" label="Representantes"
              primary="codigo" :height="258" :width="680" :minRows="11" :htSettings="{ minSpareRows: 0 }"
              style="top: 10px; left: 10px" :columns="[
                {
                  name: 'codigo',
                  header: 'Código',
                  readOnly: true,
                },
                {
                  name: 'nombre',
                  header: 'Nombre',
                  readOnly: true,
                },
                {
                  name: 'oculto',
                  header: 'Act.',
                  type: 'checkbox',
                  checkedTemplate: 0,
                  uncheckedTemplate: 1,
                  className: 'text-align-center'
                }
              ]" />
          </div>
          <div class="tab">
            <field name="notas" widget="text" placeholder="Escriba aquí las notas del artículo..." width="670px"
              height="270px" style="top: 10px; left: 12px" />
          </div>
        </r-tabs>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        Listado
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>

<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import Handsontable from "handsontable";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    var self = this;
    return {
      title: "Artículos",
      dbAdapter: "articulo",
      primary: "codigo",
      defaultData: {
        activo: 1,
      },
      sequence: {
        cancel: false,
        name: "articulo",
        params: [
          "familia_id.codigo",
          "seccion_id.codigo",
          "modelo_id.codigo",
          "proveedor.codigo",
          "talla",
        ],
      },
      wt: 0,
      fields: [{ name: 'representantes_ocultos', fields: ['codigo', 'nombre'] }],
      originalInventario: [],
      representantes: null,
      htSettingsAtributos: {
        cells(row, col, prop) {
          let readOnly = prop == 'id' && !this.instance.getSourceData()[row].atributo_id;
          return { readOnly }
        },
        beforeChange(changes, source) {
          if (!changes) return;
          let h = this;
          changes.forEach(function (change, index) {
            if (!change) return;
            const row = change[0];
            const prop = change[1];
            const newVal = change[3];
            if (prop == 'id' && h.getSourceData().find(x => x.id == newVal)) {
              self.app.toast('Ya está añadido ese valor de atributo', 'error');
              changes[index][3] = null;
            }
          });
        }
      },
      selectedConjunto: null,
      htSettingsConjuntos: {
        afterDeselect: function (a) {
          //setTimeout(() => self.selectedConjunto = null, 1000);
        },
        afterSelectionEnd: function (row) {
          self.selectedConjunto = this.getSourceData()[row].codigo ? row : null;
        }
      },
      htSettingsArticulosConjuntos: {
        afterChange: function (changes, source) {
          if (source == 'loadData') return;
          if (!changes) return;
          var h = this;
          changes.forEach(function (change, index) {
            if (!change) return;
            var row = change[0];
            var prop = change[1];
            var oldVal = change[2];
            var newVal = change[3];
            if (prop == 'ean13' || prop == 'codigo') {
              if (!newVal) {
                self.formData.articulos.splice(row, 1);
              } else {
                if (newVal.length == 12) newVal = '0' + newVal;
                if (prop == 'ean13' && newVal.includes(',')) {
                  newVal = parseInt(newVal.replace('.', '')).toString();
                  newVal = newVal.padStart(7, '0');
                  const ean_checkdigit = (code) => {
                    let sum = 0;
                    for (let i = 0; i < code.length; i++) {
                      sum += (((i + 1) % 2) * 2 + 1) * code[code.length - 1 - i];
                    }
                    return code + (10 - (sum % 10)) % 10;
                  }
                  newVal = ean_checkdigit(newVal);
                }
                window.DB.getList('articulo', {
                  limit: 1,
                  filter: [prop == 'codigo' ? 'codigo' : 'ean13', "=", newVal],
                  depth: 3,
                  fields: [
                    'codigo',
                    'ean13',
                    'nombre',
                    { name: 'seccion_id', fields: ['codigo', 'nombre'] },
                    { name: 'familia_id', fields: ['codigo', 'nombre'] },
                    { name: 'thumbnail', fields: ['imagen'] }
                  ]
                }).then(function (res) {
                  if (parseInt(res.count)) {
                    if (h.getSourceData().some((x, i) => i != row && x.codigo == res.data[0].codigo)) {
                      h.getSourceData()[row] = oldVal || {};
                      self.app.toast('Ya está añadido el artículo', 'error');
                      h.selectCell(row, prop);
                    } else {
                      h.getSourceData()[row] = res.data[0];
                      self.selectedArticulo = res.data[0];
                      //h.setDataAtRowProp(row, '_op', null);
                    }
                  } else {
                    h.getSourceData().splice(row, 1);
                    self.app.toast('No se han encontrado el artículo', 'error');
                    h.selectCell(row, prop);
                  }
                  h.render();
                  h.setDataAtRowProp(row, '_op', null);
                  self.$refs.articulos_conjuntos.field.setValueH()
                });
              }
              //h.render();
            }
          });
        }
      }
    }
  },
  watch: {
    mode: function (newVal, oldVal) {
      var self = this;
      if (newVal == "new") {
        window.DB.action("articulo", "getNextBarcode")
          .then(function (res) {
            self.$set(self.formData, "ean13", res.ean13);
          })
          .catch(function (res) {
            window.console.log(res);
          });
      }
    },
  },
  computed: {
    pCosteCifradoSustitucion() {
      var self = this;
      let pc = parseFloat(self.formData.coste_unidad_venta || 0);
      let palabra = self.app.config.palabra_cifrado;
      if (!pc || !palabra || palabra.length != 10) return "";
      pc = parseFloat(pc).toFixed(2).toString();
      return pc
        .split("")
        .map(function (c) {
          if (c == ".") return "";
          return palabra[c];
        })
        .join("");
    },
    pCosteCifradoReverso() {
      var self = this;
      let pc = parseFloat(self.formData.coste_unidad_venta || 0);
      let palabra = self.app.config.palabra_cifrado;
      if (!pc || !palabra || palabra.length != 10) return "";
      pc = parseFloat(pc).toFixed(2).toString();
      return pc
        .replace(/[^0-9]/gi, "")
        .split("")
        .reverse()
        .join("");
    },
    ptarifa() {
      var self = this;
      return function (n) {
        let precio = parseFloat(self.formData["tarifa" + n] || 0);
        if (!precio) return "";
        let pcoste = self.formData.coste_unidad_venta || 0;
        var calc = 100 * (precio / pcoste - 1);
        if (!isFinite(calc)) calc = 0;
        calc = calc.toFixed(2) + " %";
        return calc;
      };
    },
  },
  methods: {
    consolelog(a) {
      window.console.log(a);
    },
    onChangePTarifa(e) {
      var self = this;
      let pcoste = self.formData.coste_unidad_venta || 0;
      let p = (parseFloat(e.target.value) || 0) / 100;
      let name = e.target.name.substring(1);
      self.$set(self.formData, name, pcoste * (1 + p));
    },
    onSelectModelo() {
      var self = this;
      if (self.mode != "new" && self.mode != "edit") return;
      if (!self.formData.modelo_id) return;
      for (let c of self.$refs.modelo_id.field.fields) {
        if (typeof c == "object") c = c.name;
        var v = self.formData.modelo_id[c];
        self.$set(self.formData, c, v);
      }
    },
    onSelectFamilia() {
      var self = this;
      if (self.mode != "new" && self.mode != "edit") return;
      if (!self.formData.familia_id) return;
      for (let c of self.$refs.familia_id.field.fields) {
        if (typeof c == "object") c = c.name;
        var v = self.formData.familia_id[c];
        if (c == "atributos") {
          v = (self.formData.familia_id.atributos || []).map((el) => ({
            atributo_id: el,
          }));
        }
        self.$set(self.formData, c, v);
      }
    },
    refrescarInventario() {
      var self = this;
      let inventario = self.originalInventario.filter((l) =>
        self.wt == 0 ? l.wt == 0 : self.wt == 1 ? l.wt == 1 : true
      );
      inventario = self.$utils.misc.clone(inventario);
      if (self.wt == 2) {
        inventario = inventario.reduce((acc, curr) => {
          let alm = curr.almacen_id;
          if (typeof alm == 'object') alm = alm.codigo;
          if (!alm) return acc;
          const i = acc.findIndex((y) => {
            let yalm = y.almacen_id;
            if (typeof yalm == 'object') yalm = yalm.codigo;
            return yalm == alm;
          });
          if (i == -1) {
            curr.wt = 0;
            acc.push(curr);
          } else {
            acc[i].unidad += curr.unidad;
            acc[i].peso += curr.peso;
          }
          return acc;
        }, []);
      }
      self.$set(self.formData, "inventario", inventario);
    },
    refrescarRepresentantes() {
      let self = this;
      new Promise((resolve, reject) => {
        if (self.representantes) { resolve(); return; }
        window.DB.getList('representante', { filter: ['activo', '=', 1], fields: ['codigo', 'nombre'] }).then(res => {
          self.representantes = res.data;
        }).catch(_ => {
          self.representantes = [];
        }).finally(_ => resolve())
      }).then(_ => {
        let rep = self.$utils.misc.clone(self.representantes);
        rep.forEach(r => {
          if ((self.formData.representantes_ocultos || []).some(ro => ro.codigo == r.codigo)) {
            r.oculto = 1;
          } else {
            r.oculto = 0;
          }
        })
        self.$set(self.formData, "representantes_ocultos2", rep);
      })
    },
    onClickEliminarConjunto() {
      let self = this;
      if (self.selectedConjunto == null) return;
      let h = self.$refs.conjuntos.field.hotInstance;
      let r = self.selectedConjunto;
      self.selectedConjunto = null;
      h.alter("remove_row", r, 1);
      self.$refs.conjuntos.field.setValueH()
    },
    onClickCrearConjunto() {
      let self = this;
      let h = self.$refs.conjuntos.field.hotInstance;
      window.DB.action("secuencia", "getSequence", { data: { name: "conjunto" } }).then(res => {
        let row = self.formData.conjuntos?.length || 0;
        h.setDataAtRowProp(row, 'codigo', res.data, 'auto');
        //self.selectedConjunto = row;
        setTimeout(() => h.selectCell(row, 0), 300);
      })

    }
  },
  mounted() {
    var self = this;

    self.$on("loadItem", () => {
      self.originalInventario = self.$utils.misc.clone(
        self.formData.inventario
      );
      self.refrescarInventario();
      self.refrescarRepresentantes();
    });
    self.$refs["main-tabs"].$refs.toolbar.addEventListener("dblclick", function (e) {
      if (self.app.session.mode == "a") return;
      if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
        if (self.mode != "view") return;
        self.wt = self.wt == 0 ? 1 : self.wt == 1 ? 2 : 0;
        self.refrescarInventario();
      }
    });
    self.$on(['loadItem', 'setMode'], (e) => {
      self.selectedConjunto = null;
    })
  },
};
</script>

<style>
tr.wtdoc>td {
  background: #72dad7 !important;
  color: white !important;
}

.col_art.field[name="articulos"] li {
  width: 100%;
}

.col_art.field[name="articulos"] ul {
  max-height: 110px;
}
</style>

<template>
  <div>
    <window-header></window-header>
    <nav-form>
      <template v-slot:after>
        <button v-if="mode == 'new' || mode == 'edit'" class="button button-fill" @click="importLines">
          <fa-icon icon="file-import" />
          <span>Importar líneas</span>
        </button>
      </template>
      <template v-slot:actions>
        <li @click="
          descargarImagenes({
            showCompanyStamp: true,
            showCustomerLogo: true,
          })
          " v-if="itemId">
          Descargar imágenes (logo+sello)
        </li>
        <li @click="
          descargarImagenes({
            showCompanyStamp: true,
            showCustomerLogo: false,
          })
          " v-if="itemId">
          Descargar imágenes (+sello empresa)
        </li>
        <li @click="
          descargarImagenes({
            showCompanyStamp: false,
            showCustomerLogo: true,
          })
          " v-if="itemId">
          Descargar imágenes (+logo cliente)
        </li>
        <li v-if="itemId" @click="exportarLineasCSV('factura_id')">
          Exportar líneas
        </li>
        <li v-if="itemId" @click="descargarFacturae">Factura-e</li>
      </template>
      <template v-slot:reports>
        <li v-for="p in (app.config.plantillas_documento_factura_cliente || [])" :key="'pdpc-' + p.codigo"
          @click="openURL(app.urlGeneradorInformesDocumentoPrint, { id: p.codigo, conditions: [['id', '=', itemId]] })"
          :class="{ disabled: !itemId }">
          Documento: {{ p.nombre }}
        </li>
        <li v-for="p in (app.config.plantillas_etiqueta_factura_cliente || [])" :key="'pdpc-' + p.codigo"
          @click="openURL(app.urlGeneradorInformesEtiquetaPrint, { id: p.codigo, conditions: [['id', '=', itemId]] })"
          :class="{ disabled: !itemId }">
          Etiquetas: {{ p.nombre }}
        </li>
      </template>
    </nav-form>
    <section>
      <article style="height: 415px">
        <field name="ndoc" widget="char" searchable label="Nº Factura" width="110px" style="top: 5px; left: 5px" />
        <field name="fecha" type="date" label="Fecha" width="100px" style="top: 5px; left: 130px" />
        <field autofocus ref="cliente_id" name="cliente_id" widget="m2o" label="Cliente" searchable width="320px"
          style="top: 5px; right: 70px" labelProp="nombre_comercial" imgProp="imagen.imagen"
          :filter="['activo', '=', 1]" :fields="[
            'nacionalidad',
            {
              name: 'divisa_id',
              fields: ['codigo', 'nombre', 'ratio']
            },
            {
              name: 'impuesto_id',
              fields: ['nombre', 'valor']
            },
            'descuento_especial',
            'financiacion',
            {
              name: 'bancos',
              fields: ['predet', 'titular', 'cuenta', 'swift'],
            },
            {
              name: 'direcciones',
              fields: [
                'predet',
                'descripcion',
                'responsable',
                'direccion',
                'direccion1',
                'cp',
                'poblacion',
                { name: 'provincia', fields: ['codigo', 'nombre'] },
                { name: 'pais', fields: ['codigo', 'nombre'] },
                'telefono',
                'telefono1',
                'movil',
                'movil1',
                'fax',
              ],
            },
            'margen',
            {
              name: 'representantes_ids',
              fields: [
                'codigo',
                'imagen',
                'nombre',
                { name: 'almacen', fields: ['codigo', 'nombre'] },
              ],
            },
            'tarifa',
            { name: 'comentarios', fields: ['descripcion', 'fecha', 'activo'] },
            {
              name: 'forma_pago_id',
              fields: [
                'codigo',
                'descripcion',
                'vencimientos',
                'periodicidad',
                'periodicidad_unidades',
              ],
            },
            'descuento_linea_unidad',
            'descuento_linea_peso',
            'recargo_equivalencia',
            'merma',
            'merma_linea'
          ]" />
        <a href="#" v-tooltip="'Mostrar comentarios'" class="button button-plain"
          :class="{ disabled: !formData.cliente_id }" style="position: absolute; top: 15px; right: 37px"
          @click="mostrarComentariosCliente">
          <fa-icon icon="sticky-note" :class="formData.cliente_id &&
            formData.cliente_id.comentarios &&
            formData.cliente_id.comentarios.length
            ? 'color-green'
            : ''
            " />
        </a>
        <a href="#" v-tooltip="'Abrir saldos'" class="button button-plain" :class="{ disabled: !formData.cliente_id }"
          style="position: absolute; top: 15px; right: 20px" @click="abrirSaldos">
          <fa-icon icon="dollar-sign" />
        </a>
        <span v-tooltip="parseInt(formData.cliente_id.recargo_equivalencia || 0)
          ? 'Tiene recargo de equivalencia'
          : 'No tiene recargo de equivalencia'
          " style="position: absolute; top: 20px; right: 8px; color: green" :style="parseInt(formData.cliente_id.recargo_equivalencia || 0)
            ? 'color: green;'
            : 'color: gray; text-decoration: line-through;'
            " v-if="formData.cliente_id">RE</span>
        <r-tabs ref="main-tabs" style="top: 40px; height: 373px" :wt="formData.wt" :buttons="[
          'Cabecera',
          'Envío',
          'Líneas',
          'Pie',
          'Vtos',
          'Taquería',
          'Estadísticas',
          'Discrepancias',
        ]" :classButton="{ disabled: !formData.cliente_id && mode != 'search' }" @active="onActiveTab">
          <div class="tab">
            <field name="npedido" widget="char" label="S. Nº de Pedido" width="80px" style="top: 10px; left: 10px" />
            <field name="fpedido" type="date" label="F. Pedido" width="100px" style="top: 10px; left: 100px" />
            <field name="representante_id" widget="m2o" label="Representante" width="350px"
              style="top: 10px; left: 210px" imgProp="imagen" :filter="['activo', '=', 1]"
              :fields="[{ name: 'almacen', fields: ['codigo', 'nombre'] }]" />

            <fieldset style="position: relative;left: 10px;top: 50px;width: 230px;height: 59px;">
              <legend>Exportación</legend>
              <field name="importacion" widget="radio" :readonly="true" default="union_europea" :options="{
                Nacional: 'nacional',
                'Extracom.': 'extracomunitario',
                UE: 'union_europea',
              }" inline-label style="top: 6px; left: 20px" />
            </fieldset>
            <field ref="divisa_id" name="divisa_id" widget="m2o" label="Divisa" width="120px"
              style="top: 115px; left: 10px" :fields="['ratio']" external-window="moneda" />
            <field name="cambio" widget="float" type="number" dec="6" label="Cambio" width="80px"
              style="top: 115px; left: 160px" :class="{ wrong: formData.divisa_id && !parseFloat(formData.cambio) }" />
            <fieldset style="
                position: relative;
                left: 250px;
                top: -9px;
                width: 380px;
                height: 59px;
              ">
              <legend>Metal</legend>
              <field ref="tipo_metal" name="tipo_metal" widget="radio" default="no"
                :options="{ No: 'no', Cuenta: 'cuenta', Factura: 'factura' }" style="top: 6px; left: 15px" />
              <field ref="metal_id" name="metal_id" widget="m2o" labelProp="descripcion" :readonly="formData.tipo_metal != 'cuenta' &&
                formData.tipo_metal != 'factura'
                " width="170px" style="top: 7px; left: 192px" :fields="['cotizacion', 'factor', 'metal']"
                :templateResult="templateMetalId" :templateSelection="templateMetalId" />
            </fieldset>

            <field name="prmetalp" widget="float" type="number" dec="2" inputStyle="text-align:center;"
              label="Pr. Metal/P" width="70px" style="top: 115px; left: 275px" :readonly="formData.tipo_metal != 'cuenta' &&
                formData.tipo_metal != 'factura'
                " />
            <field name="factor" widget="float" inputStyle="text-align:right;font-size:10px;" type="number" dec="4"
              inline-label label="Factor" width="40px" style="top: 153px; left: 275px" :readonly="true" />
            <field name="merma" widget="float" type="number" dec="2" inputStyle="text-align:center;" label="Merma (%/m)"
              width="70px" style="top: 115px; left: 350px" :readonly="formData.tipo_metal != 'cuenta' &&
                formData.tipo_metal != 'factura'
                " />
            <field name="contraste" widget="float" type="number" dec="2" inputStyle="text-align:center;"
              label="Contraste/P" width="70px" style="top: 115px; left: 425px" />
            <field name="ocostes" widget="float" type="number" dec="2" inputStyle="text-align:center;"
              label="O. Costes/P" width="70px" style="top: 115px; left: 500px" />
            <field name="ocostesu" widget="float" type="number" dec="2" inputStyle="text-align:center;"
              label="O. Costes/U" width="70px" style="top: 115px; left: 575px" />
            <field name="mermaporlinea" widget="checkbox" inline-label label="Merma por Línea"
              style="top: 153px; left: 526px" />
            <field name="adjuntos" :max="6" widget="files" label="Adjuntos" style="top: 168px; left: 11px" />
            <field name="observaciones" widget="text" label="Observaciones" width="429px" height="40px"
              style="top: 168px; right: 11px" />

            <field name="delegacion_id" widget="m2o" label="Deleg." width="260px" style="top: 240px; right: 350px"
              :readonly="mode == 'edit'" inputStyle="font-weight:bold;text-align:center;" inline-label />
            <field name="tipo_id" widget="m2o" label="Tipo" width="260px" style="top: 270px; right: 350px"
              :readonly="mode == 'edit'" searchable inline-label />
            <field ref="pedido_id" name="pedido_id" widget="m2o" label="Pedido origen" labelProp="ndoc" primary="id"
              width="250px" style="top: 240px; right: 10px"
              :fields="['ndoc', { name: 'cliente_id', fields: ['nombre_comercial'] }, 'total']"
              :filter="[['estado', '=', 'preparado']]"
              :templateSelection="item => item.id ? $utils.misc.$(`<span style='display:flex; align-items: center;'><span class='codelabel'>` + item.ndoc + `</span><span class='subcodelabel'>` + item.cliente_id.codigo + `</span>` + item.cliente_id.nombre_comercial + `<span class='codelabel'>` + item.total.toFixed(2) + `€</span></span>`) : null"
              :templateResult="item => item.id ? $utils.misc.$(`<span style='display:flex; align-items: center;'><span class='codelabel'>` + item.ndoc + `</span><span class='subcodelabel'>` + item.cliente_id.codigo + `</span>` + item.cliente_id.nombre_comercial + `<span class='codelabel'>` + item.total.toFixed(2) + `€</span></span>`) : null"
              inline-label :readonly="!!formData.albaran_id" />
            <field ref="albaran_id" name="albaran_id" widget="m2o" label="Albarán origen" labelProp="cliente_id"
              primary="id" width="250px" style="top: 270px; right: 10px"
              :fields="['ndoc', { name: 'cliente_id', fields: ['nombre_comercial'] }, 'total']"
              :filter="[['estado', '=', 'albaranado']]"
              :templateSelection="item => item.id ? $utils.misc.$(`<span style='display:flex; align-items: center;'><span class='codelabel'>` + item.ndoc + `</span><span class='subcodelabel'>` + item.cliente_id.codigo + `</span>` + item.cliente_id.nombre_comercial + `<span class='codelabel'>` + item.total.toFixed(2) + `€</span></span>`) : null"
              :templateResult="item => item.id ? $utils.misc.$(`<span style='display:flex; align-items: center;'><span class='codelabel'>` + item.ndoc + `</span><span class='subcodelabel'>` + item.cliente_id.codigo + `</span>` + item.cliente_id.nombre_comercial + `<span class='codelabel'>` + item.total.toFixed(2) + `€</span></span>`) : null"
              inline-label :readonly="!!formData.pedido_id" />
            <field name="estado" widget="radio" class="pedido-color" :options="{
              Pendiente: 'pendiente',
              Procesando: 'procesando',
              Facturado: 'facturado',
              Cancelado: 'cancelado',
            }" style="top: 300px; left: 15px" />
          </div>
          <div class="tab">
            <!--Envío -->
            <fieldset name="envio" style="
                position: relative;
                left: 10px;
                top: 10px;
                width: 630px;
                height: 177px;
              ">
              <legend>Dirección Comercial</legend>
              <field name="dirc_tipo" labelProp="descripcion" primary="id" widget="m2o" width="413px"
                style="top: 5px; left: 8px" :fields="[
                  'descripcion',
                  'responsable',
                  'direccion',
                  'direccion1',
                  'cp',
                  'poblacion',
                  { name: 'provincia', fields: ['codigo', 'nombre'] },
                  { name: 'pais', fields: ['codigo', 'nombre'] },
                  'telefono',
                  'telefono1',
                  'movil',
                  'movil1',
                  'fax',
                ]" :readonly="!formData.cliente_id" :filter="formData.cliente_id
                  ? ['cliente_id', '=', formData.cliente_id.codigo]
                  : []
                  " />
              <field name="dirc_tipo.responsable" widget="char" label="Responsable" inline-label width="340px"
                style="top: 30px; right: 206px" :readonly="true" />
              <field name="dirc_tipo.direccion" widget="char" label="Dirección" inline-label width="340px"
                style="top: 55px; right: 206px" :readonly="true" />
              <field name="dirc_tipo.direccion1" widget="char" label="Dirección 1" inline-label width="340px"
                style="top: 80px; right: 206px" :readonly="true" />
              <field name="dirc_tipo.cp" widget="char" label="CP" inline-label width="100px"
                style="top: 105px; right: 446px" :readonly="true" />
              <field name="dirc_tipo.poblacion" widget="char" label="Población" inline-label width="180px"
                style="top: 105px; right: 206px" :readonly="true" />
              <field name="dirc_tipo.provincia" widget="m2o" label="Provincia" inline-label width="150px"
                style="top: 130px; right: 396px" :readonly="true" />
              <field name="dirc_tipo.pais" widget="m2o" label="País" inline-label width="150px"
                style="top: 130px; right: 206px" :readonly="true" />

              <field name="dirc_tipo.telefono" widget="char" label="Teléfono" inline-label width="100px"
                style="top: 5px; right: 8px" :readonly="true" />
              <field name="dirc_tipo.telefono1" widget="char" label="Teléfono 1" inline-label width="100px"
                style="top: 30px; right: 8px" :readonly="true" />
              <field name="dirc_tipo.movil" widget="char" label="Móvil" inline-label width="100px"
                style="top: 55px; right: 8px" :readonly="true" />
              <field name="dirc_tipo.movil1" widget="char" label="Móvil 1" inline-label width="100px"
                style="top: 80px; right: 8px" :readonly="true" />
              <field name="dirc_tipo.fax" widget="char" label="Fax" inline-label width="100px"
                style="top: 105px; right: 8px" :readonly="true" />
            </fieldset>
          </div>
          <div class="tab">
            <field ref="lineas" name="lineas" class="lineas" :htSettings="{ rowHeights: 15 }" widget="handsontable"
              :height="316" :minRows="19" style="top: 5px; left: 4px; width: 648px" customprop="lineas_customprop"
              :fields="[
                {
                  name: 'origin_pedido_id',
                  fields: [{ name: 'pedido_id', fields: ['ndoc'] }],
                },
                {
                  name: 'origin_albaran_id',
                  fields: [{ name: 'albaran_id', fields: ['ndoc'] }],
                },
              ]" :columns="[
                {
                  name: 'ean13',
                  header: '|||||||||',
                  help: 'Código de barras del artículo',
                },
                {
                  name: 'articulo_id',
                  header: 'Artículo',
                  help: 'Código del artículo',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  noOpen: true,
                  fields: [
                    { name: 'thumbnail', fields: ['imagen'] },
                    { name: 'familia_id', fields: ['codigo', 'nombre'] },
                    'tarifa1',
                    'tarifa2',
                    'tarifa3',
                    'tarifa4',
                    'tarifa5',
                    'tarifa6',
                    'tarifa7',
                    'tarifa8',
                    'tarifa9',
                    'tarifa10',
                  ],
                },
                {
                  name: 'descripcion',
                  help: 'Descripción del artículo',
                  header: 'Descripción',
                },
                {
                  name: 'modelo_id',
                  header: 'Modelo',
                  help: 'Modelo del artículo',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  readOnly: true,
                },
                //{name:'familia_id',header:'Familia', type:'m2o', primary:'codigo', label:'codigo', readOnly:true},
                { name: 'nota', help: 'Notas del artículo', header: 'Nota' },
                {
                  name: 'tt',
                  header: 'T',
                  help: 'Almacen de donde sale la mercancía',
                  type: 'select',
                  options: ['A', 'R'],
                },
                {
                  name: 'almacen_id',
                  header: 'Alm.',
                  help: 'Numero de almacén',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  readOnly: true,
                },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  help: 'Unidades vendidas',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.0' },
                },
                {
                  name: 'etiqueta',
                  header: 'Etiq.',
                  help: 'Etiquetas de unidades vendidas',
                  type: 'numeric'
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  help: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'taqueria_ids',
                  header: 'Taquería',
                  help: 'Cód. Taqueria',
                  type: 'm2m',
                  primary: 'codigo',
                  image: 'thumbnail.imagen',
                  fields: ['precio_coste'],
                  label: 'codigo',
                  filter: () => ['seccion_id', '=', 'PCK'],
                },
                {
                  name: 'taqueria_precio',
                  header: 'Taq.(€)',
                  help: 'Coste total de taqueria',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'v',
                  header: 'V',
                  help: '<U> Venta por unidad <P> Venta por peso',
                  type: 'select',
                  options: ['U', 'P'],
                },
                {
                  name: 'm',
                  header: 'M',
                  help: 'Venta con metal incluido',
                  type: 'checkbox',
                },
                {
                  name: 'r',
                  header: 'R',
                  help: 'Venta con rec. equivalencia',
                  type: 'checkbox',
                },
                {
                  name: 'precio_coste',
                  header: 'P.C.',
                  help: 'Precio coste artículo',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  renderer: rendererRojoNegativo,
                },
                {
                  name: 'tt2',
                  header: 'T',
                  help: 'Tarifa de venta',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0' },
                },
                {
                  name: 'precio',
                  header: 'Precio',
                  help: 'Precio Venta',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'descuentos',
                  header: 'Dtos.',
                  help: 'Dto. aplicado al artículo',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                /*{
                  name: 'descuento_peso',
                  header: 'Dto./peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },*/
                {
                  name: 'totallinea',
                  header: 'Total Línea',
                  help: 'Total Línea',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'm2',
                  header: 'M',
                  help: 'Marcar si se acumula el peso del metal',
                  type: 'checkbox',
                },
                {
                  name: 'c2',
                  header: 'C',
                  help: 'Marcar si se aplica Contraste por peso',
                  type: 'checkbox',
                },
                {
                  name: 'p',
                  header: 'P',
                  help: 'Marcar si se aplica Otros Costes por Peso',
                  type: 'checkbox',
                },
                {
                  name: 'u',
                  header: 'U',
                  help: 'Marcar si se aplica Otros Costes por Unidad',
                  type: 'checkbox',
                },
                {
                  name: 'com',
                  header: 'Com.',
                  help: 'Tipo de Comisión del artículo',
                  type: 'm2o',
                  label: 'codigo',
                  primary: 'codigo',
                  emptyOnError: true,
                },
                {
                  name: 'c',
                  header: 'C.',
                  help: '<D> Comisión Directa <I> Comisión Indirecta',
                  type: 'select',
                  options: ['D', 'I'],
                },
                {
                  name: 'margen',
                  header: '%',
                  help: '% de Margen para el calculo del Prec. del cliente',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'pventa',
                  header: 'P.V.P.',
                  help: 'PVP del cliente',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                /*{ name: 'e', header: 'E', type: 'checkbox' },*/
                {
                  name: 'origin_pedido_id',
                  header: 'Pedido origen',
                  help: 'Pedido del que procede la factura',
                  type: 'm2o',
                  primary: 'id',
                  label: 'id',
                  readOnly: true,
                  tplTd: (data, c) => typeof data == 'object' ? data.pedido_id.ndoc : null,
                },
                {
                  name: 'origin_albaran_id',
                  header: 'Albarán origen',
                  help: 'Albarán del que procede la factura',
                  type: 'm2o',
                  primary: 'id',
                  label: 'id',
                  readOnly: true,
                  tplTd: (data, c) => typeof data == 'object' ? data.albaran_id.ndoc : null,
                },
              ]" />
          </div>
          <div class="tab">
            <field name="total_unidades" widget="float" type="number" dec="2" label="Total Unidades" width="90px"
              style="top: 10px; left: 15px" :readonly="true" />
            <field name="total_peso" widget="float" type="number" dec="3" label="Total Peso" width="90px"
              style="top: 10px; left: 115px" :readonly="true" suffix=" g" />
            <field name="g18k" widget="float" type="number" dec="3" :label="(formData.metal_id && formData.metal_id.descripcion) ||
              'G. Aleación'
              " width="90px" style="top: 50px; left: 15px" suffix=" g" />
            <field name="gmerma" widget="float" type="number" dec="3" label="G. Merma" width="90px"
              style="top: 50px; left: 115px" suffix=" g" />

            <field name="total_articulos" widget="float" type="number" dec="2" label="Total Artículos" inline-label
              width="70px" style="top: 10px; right: 12px" :readonly="true" />
            <field name="dto_especial_porcentaje" widget="float" type="number" dec="2" label="Dto. Especial"
              inline-label width="90px" style="top: 30px; right: 90px" suffix=" %" />
            <field name="dto_especial" widget="float" type="number" dec="2" width="70px"
              style="top: 30px; right: 12px" />

            <field name="g24k_porcentaje" widget="float" type="number" dec="3" :label="(formData.metal_id && formData.metal_id.descripcion) ||
              'G. Aleación'
              " inline-label width="90px" style="top: 50px; right: 90px" suffix=" g" :readonly="true" />
            <field name="g24k" widget="float" type="number" dec="2" width="70px" style="top: 50px; right: 12px"
              :readonly="true" />
            <field name="gcontraste_porcentaje" type="number" dec="3" widget="float" label="G. Contraste" inline-label
              width="90px" style="top: 70px; right: 90px" suffix=" g" />
            <field name="gcontraste" widget="float" type="number" dec="2" width="70px" style="top: 70px; right: 12px"
              :readonly="true" />
            <field name="gotroscostes_porcentaje" widget="float" type="number" dec="3" label="G. Otros Costes"
              inline-label width="90px" style="top: 90px; right: 90px" suffix=" g" />
            <field name="gotroscostes" widget="float" type="number" dec="2" width="70px" style="top: 90px; right: 12px"
              :readonly="true" />
            <field name="uotroscostes_porcentaje" widget="float" type="number" dec="3" label="U. Otros Costes"
              inline-label width="90px" style="top: 110px; right: 90px" />
            <field name="uotroscostes" widget="float" type="number" dec="2" width="70px" style="top: 110px; right: 12px"
              :readonly="true" />
            <field name="portesyseguros" widget="float" label="Portes y Seguros" inline-label width="70px"
              style="top: 130px; right: 12px" type="number" dec="2" />
            <field name="financiacion_porcentaje" widget="float" type="number" dec="2" label="Financiación" inline-label
              width="90px" style="top: 150px; right: 90px" suffix=" %" />
            <field name="financiacion" widget="float" type="number" dec="2" inline-label width="70px"
              style="top: 150px; right: 12px" />

            <field name="documento_exportado" widget="checkbox" label="Documento exportado" reverse inline-label
              style="top: 120px; left: 5px" />
            <field name="documento_factura_emitido" widget="checkbox" label="Documento de factura emitido" reverse
              inline-label style="top: 140px; left: 5px" />

            <div style="position: absolute; bottom: 0px">
              <table class="tabla-pie">
                <tr>
                  <td></td>
                  <th>B. Imponible</th>
                  <th>
                    {{ formData.tipo_impuesto }}
                    ({{ formData.ivap }}%)
                  </th>
                  <th>
                    Recargo ({{ $utils.misc.formatNumber(formData.rep) }}%)
                  </th>
                  <th>
                    <b>Totales</b>
                  </th>
                </tr>
                <tr>
                  <td style="width: 100px">Base 1 <small>(IMP.)</small></td>
                  <td>
                    <field name="baseimponible_sre" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </td>
                  <td>
                    <field name="iva_sre" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </td>
                  <td>
                    <field name="recargo_equivalencia_sre" widget="float" type="number" width="100%"
                      style="position: relative" :readonly="true" />
                  </td>
                  <td>
                    <field name="total2" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </td>
                </tr>
                <tr>
                  <td style="width: 115px">Base 2 <small>(IMP.+RE)</small></td>
                  <td>
                    <field name="baseimponible_re" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </td>
                  <td>
                    <field name="iva_re" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </td>
                  <td>
                    <field name="recargo_equivalencia_re" widget="float" type="number" width="100%"
                      style="position: relative" :readonly="true" />
                  </td>
                  <td>
                    <field name="total1" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </td>
                </tr>
                <tr>
                  <td>
                    Base Metal
                    <field name="base_metal_check" widget="checkbox" style="display: inline-flex" />
                  </td>
                  <td>
                    <field name="baseimponible_metal" widget="float" type="number" width="100%"
                      style="position: relative" :readonly="true" />
                  </td>
                  <td>
                    <field name="iva_metal" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </td>
                  <td>
                    <field name="recargo_equivalencia_metal" widget="float" type="number" width="100%"
                      style="position: relative" :readonly="true" />
                  </td>
                  <td>
                    <field name="total_metal" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </td>
                </tr>
                <tr>
                  <th colspan="4" class="total-cell">Rec. Financiero</th>
                  <th>
                    <field name="financiacion" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" />
                  </th>
                </tr>
                <tr>
                  <th colspan="4" class="total-cell">Total</th>
                  <th>
                    <field name="total" widget="float" type="number" width="100%" style="position: relative"
                      :readonly="true" inputStyle="font-weight:bold; background: rgba(0, 140, 186, 0.3)!important;" />
                  </th>
                </tr>
              </table>
            </div>
          </div>
          <div class="tab">
            <div class="item-input inline-label field" style="top: 10px; right: 530px">
              <div class="item-inner">
                <label class="item-title item-label">Base</label>
                <div class="item-input-wrap">
                  <input name="sum_bi" :value="sum_bi" type="text" style="width: 80px; text-align: right" readonly />
                </div>
              </div>
            </div>
            <div class="item-input inline-label field" style="top: 30px; right: 530px">
              <div class="item-inner">
                <label class="item-title item-label">IVA</label>
                <div class="item-input-wrap">
                  <input name="sum_iva" :value="sum_iva" type="text" style="width: 80px; text-align: right" readonly />
                </div>
              </div>
            </div>
            <div class="item-input inline-label field" style="top: 50px; right: 530px">
              <div class="item-inner">
                <label class="item-title item-label">RE</label>
                <div class="item-input-wrap">
                  <input name="sum_re" :value="sum_re" type="text" style="width: 80px; text-align: right" readonly />
                </div>
              </div>
            </div>
            <field name="total" widget="float" type="number" dec="2" label="Total" inline-label width="80px"
              style="top: 70px; right: 530px" :readonly="true" inputStyle="font-size:22px;" />

            <field name="total_unidades" widget="float" type="number" dec="2" label="Total Unidades" width="80px"
              style="top: 20px; right: 400px" :readonly="true" />
            <field name="total_peso" widget="float" type="number" dec="3" label="Total Peso" width="80px"
              style="top: 50px; right: 400px" :readonly="true" suffix=" g" />

            <field name="formapago_id" label="Forma de pago" widget="m2o" width="325px" style="top: 100px; left: 14px"
              labelProp="descripcion" :fields="[
                'vencimientos',
                'periodicidad',
                'periodicidad_unidades',
              ]" external-window="forma_pago" />
            <field name="comisiones_liquidadas" widget="checkbox" inline-label label="Comisiones liquidadas"
              style="top: 140px; left: 14px" reverse :readonly="true" />
            <field name="liquidado" widget="float" type="number" dec="2" placeholder="0.00€" width="80px"
              style="top: 140px; left: 170px" :readonly="true" />

            <field name="titular" widget="char" label="Titular" inline-label placeholder="Titular" width="260px"
              style="top: 190px; left: 11px" />
            <fieldset style="
                position: absolute;
                left: 10px;
                top: 210px;
                width: 350px;
                height: 70px;
              ">
              <legend>Nº Cta.</legend>
              <div style="position: relative">
                <field name="cuenta" widget="char" label="Cuenta" width="234px" style="left: 0px" />

                <field name="swift" widget="char" label="Código Swift" width="92px" style="left: 240px" />
              </div>
            </fieldset>

            <field ref="vencimientos" name="vencimientos" widget="handsontable" label="Vencimientos" :height="223"
              style="top: 10px; right: 4px; width: 280px" :fields="[{ name: 'remesa', fields: ['id'] }]"
              :htSettings="vencimientos_customprop" :columns="[
                { name: 'fecha', header: 'Fecha', type: 'date2' },
                {
                  name: 'importe',
                  header: 'Importe',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
              ]" />
          </div>
          <div class="tab">
            <field ref="taqueria_lineas" name="taqueria_lineas" widget="handsontable"
              :readonly="!['pendiente', 'procesando'].includes(formData.estado)" :height="278" :minRows="12"
              style="top: 10px; left: 10px; width: 327px" customprop="taquerialineas_customprop" :columns="[
                {
                  name: 'taqueria_id',
                  header: 'Artículo',
                  type: 'm2o',
                  primary: 'codigo',
                  showCode: true,
                  image: 'thumbnail.imagen',
                  label: 'nombre',
                  filter: (value, row, prop) => ['seccion_id', '=', 'PCK']
                },
                {
                  name: 'unidades_teoricas',
                  header: 'Uds. Teoricas',
                  type: 'numeric',
                  format: '0',
                  readOnly: true,
                },
                {
                  name: 'unidades_reales',
                  header: 'Uds. Reales',
                  type: 'numeric',
                  format: '0',
                },
              ]" />

            <field ref="reglas_taqueria" name="reglas_taqueria" widget="handsontable"
              :readonly="!['pendiente', 'procesando'].includes(formData.estado)" :height="141" :minRows="5"
              style="top: 10px; left: 347px; width: 300px" :columns="[
                {
                  name: 'taqueria_ids',
                  header: 'Taquería',
                  help: 'Cód. Taqueria',
                  type: 'm2m',
                  primary: 'codigo',
                  image: 'thumbnail.imagen',
                  fields: ['precio_coste'],
                  label: 'codigo',
                  model: 'articulo',
                  filter: () => ['seccion_id', '=', 'PCK'],
                },
                {
                  name: 'precio_minimo',
                  header: 'P. mín.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                },
                {
                  name: 'precio_maximo',
                  header: 'P. máx',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                },
              ]" />
            <fieldset style="position: absolute;  left: 345px; top: 155px; width: 300px; height: 55px;">
              <legend>Cálculo de taquería</legend>
              <field name="calculo_taqueria" widget="radio" :options="{
                'Asignación en líneas': 'lineas',
                'Reglas por precio': 'reglas',
              }" inline-label style="top: 6px; left: 20px"
                :readonly="!['pendiente', 'procesando'].includes(formData.estado)" />
            </fieldset>
          </div>
          <div class="tab">
            <field name="total_unidades" widget="float" type="number" dec="2" label="Total Unidades" inline-label
              width="80px" style="top: 10px; left: 14px" :readonly="true" />
            <field name="total_peso" widget="float" type="number" dec="3" label="Total Peso" inline-label width="80px"
              style="top: 10px; left: 195px" :readonly="true" suffix=" g" />
            <field name="total" widget="float" type="number" dec="2" label="Total Importe" inline-label width="80px"
              style="top: 10px; left: 350px" :readonly="true" />
            <div class="item-input inline-label field" style="top: 10px; left: 520px">
              <div class="item-inner">
                <label class="item-title item-label">€/pieza</label>
                <div class="item-input-wrap">
                  <input name="precio_medio_pieza" type="text" style="width: 80px; text-align: right"
                    :value="precioMedioPieza" readonly />
                </div>
              </div>
            </div>
            <field ref="estadisticas_familias" name="estadisticas_familias" widget="handsontable" :height="247"
              :minRows="10" style="top: 45px; left: 10px; width: 637px" :readonly="true" :columns="[
                {
                  name: 'familia_id',
                  header: 'Familia',
                  type: 'm2o',
                  label: 'nombre',
                  primary: 'codigo',
                  showCode: true,
                  model: 'familia',
                },
                {
                  name: 'unidades',
                  header: 'Unidades',
                  type: 'numeric',
                  format: '0',
                },
                {
                  name: 'precio_medio',
                  header: 'Precio medio',
                  type: 'numeric',
                  format: '0,0.00',
                },
              ]" />
            <field ref="historial_estados" name="historial_estados" widget="handsontable" :height="247" :minRows="10"
              style="top: 45px; left: 410px; width: 230px" :readonly="true" :columns="[
                {
                  name: 'estado',
                  header: 'Estado',
                  type: 'select',
                  options: {
                    pendiente: 'Pendiente',
                    proceso: 'En proceso',
                    cancelado: 'Cancelado',
                    finalizado: 'Finalizado',
                  },
                },
                {
                  name: 'create_date',
                  header: 'Fecha',
                  renderer: rendererDatetime,
                },
              ]" />
          </div>
          <div class="tab">
            <field ref="discrepancias" name="discrepancias" widget="handsontable" :height="278" :minRows="12"
              style="top: 10px; left: 10px; width: 627px" readonly :columns="[
                {
                  name: 'articulo_id',
                  header: 'Artículo',
                  type: 'm2o',
                  primary: 'codigo',
                  showCode: true,
                  image: 'thumbnail.imagen',
                  label: 'nombre',
                  model: 'articulo',
                },
                {
                  name: 'unidades_pedidas',
                  header: 'Uds. pedidas',
                  type: 'numeric',
                  format: '0',
                },
                {
                  name: 'unidades_facturadas',
                  header: 'Uds. facturadas',
                  type: 'numeric',
                  format: '0',
                },
                {
                  name: 'unidades_discrepancia',
                  header: 'Uds. discrepancia',
                  type: 'numeric',
                  format: '0',
                },
              ]" />
          </div>
        </r-tabs>
        <!-- tabs -->
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        Listado
        <div class="item-after">
          <field widget="select" name="anio" style="position: relative; width: 100px; margin-right: 24px;" width="100"
            @click="$event.stopPropagation()" :options="years.reduce((a, v) => ({ ...a, [v]: v }), {})" searchable
            :readonly="mode != 'search'" placeholder="Todos los años" v-if="years.length" />
          <!--<select
            style="margin-right: 24px;"
            @click="$event.stopPropagation()"
            @change=""
          >
            <option value="">Todos</option>
            <option
              v-for="y in years"
              :key="dbAdapter+'-'+y"
              :value="y"
            >{{y}}</option>
          </select>-->
          {{ count }}
        </div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import VentaMixin from "./../components/VentaMixin.js";
import FacturaMixin from "./../components/FacturaMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin, FacturaMixin, VentaMixin],
  data: function () {
    var self = this;
    return {
      title: "Facturas de cliente",
      dbAdapter: "factura_cliente",
      primary: "id",
      defaultDataSearch: {
        wt: 0,
        anio: new Date().getFullYear(),
        //tipo_id: self.app.config.tipo_documento_defecto
      },
      defaultData: {
        wt: 0,
        fecha: new Date().yyyymmdd(),
        fpedido: new Date().yyyymmdd(),
        tipo_metal: "no",
        merma: 0,
        prmetalp: 0,
        contraste: 0,
        ocostes: 0,
        ocostesu: 0,
        delegacion_id: self.app.config.delegacion_defecto || null,
        portesyseguros: 0,
        estado: 'procesando',
        calculo_taqueria: 'lineas',
        tipo_id: self.app.config.tipo_documento_defecto,
      },
      lineas_pedido_cliente: false,
      /*sequence: {
        field: "ndoc",
        name: function () {
          return self.formData.tipo_id
            ? "factura_venta_" +
                (self.formData.wt ? "1" : "0") +
                "_" +
                self.formData.tipo_id.codigo
            : null;
        },
        params: ["tipo_id.codigo", "wt"],
      },*/
      vencimientos_customprop: {
        cells: function (row, col, prop) {
          var cellProperties = {};
          if (!self.$refs.vencimientos.field) return;
          let h = self.$refs.vencimientos.field.hotInstance;
          if (typeof h.getSourceData()[row] == "undefined") return;
          var remesa = h.getSourceData()[row]["remesa"];
          cellProperties.readOnly = self.mode == "empty" || self.mode == "view" || remesa?.id;
          return cellProperties;
        }
      }
    };
  },
  mounted() {
    var self = this;
    setTimeout(function () {
      self.actualizarTodo(true);
    }, 1000);
  },
  methods: {
    onActiveTab(i) {
      let self = this;
      switch (i) {
        case 3: //pie
        case 4: //vtos
        case 5: //taqueria
        case 6: //estadisticas
        case 7: //discrepancias
          self.actualizar_total_unidades();
          self.actualizar_total_peso();
          self.actualizar_total_articulos();
          self.actualizar_g18k();
          self.actualizar_gmerma();
          self.actualizar_gcontraste_porcentaje();
          self.actualizar_gotroscostes_porcentaje();
          self.actualizar_uotroscostes_porcentaje();
          self.actualizar_taqueria();
          self.actualizar_estadisticas_familias();
          self.actualizar_discrepancias();
          break;
      }
    },
    actualizar_discrepancias() {
      var self = this;
      let h = self.$refs.lineas.field.hotInstance;
      let datos = {};
      if (!self.formData.pedido_id?.id) return;
      let p = Promise.resolve();
      if (!self.lineas_pedido_cliente) {
        p = p.then(() => window.DB.getList('linea_pedido_cliente', { filter: [['pedido_id', '=', self.formData.pedido_id.id]], fields: [{ name: 'articulo_id', fields: ['codigo', 'nombre', 'ean13'] }, 'unidades'] }))
          .then(res => self.lineas_pedido_cliente = res.data);
      }
      p.then(_ => {
        self.lineas_pedido_cliente.forEach(l => {
          let c = l.articulo_id.codigo;
          datos[c] = datos[c] || { unidades_pedidas: 0, unidades_facturadas: 0 };
          datos[c].unidades_pedidas += l.unidades;
        })
        h.getSourceData().forEach(function (l) {
          if (!l.unidades) return;
          let c = l.articulo_id;
          if (typeof c == 'object') c = c.codigo;
          datos[c] = datos[c] || { unidades_pedidas: 0, unidades_facturadas: 0 };
          datos[c].unidades_facturadas += l.unidades;
        });
        let htData = Object.keys(datos).map((id) => ({
          articulo_id: id,
          unidades_pedidas: datos[id].unidades_pedidas,
          unidades_facturadas: datos[id].unidades_facturadas,
          unidades_discrepancia: datos[id].unidades_pedidas - datos[id].unidades_facturadas,
        })).filter(x => x.unidades_discrepancia != 0);
        /*htData.sort((a, b) =>
          a.codigo > b.codigo ? 1 : a.codigo < b.codigo ? -1 : 0
        );*/
        self.$set(self.formData, "discrepancias", htData);
      });


    },
    openURL(url, params = {}) {
      params.token = window.session.token;
      let ser_params = window.$.param(params);
      window.open(url + '&' + ser_params, "_blank");
    },
    descargarFacturae() {
      var self = this;
      window.open(`${window.DB.server}${self.dbAdapter}?${window.$.param({
        action: "factura_electronica",
        id: self.itemId,
        token: self.app.session.token,
      })}`, "_blank");
    }
  },
};
</script>
<style>
.pedido-color.field[name="estado"] .buttonset .button.active {
  background-color: #ffffbb;
  color: black;
}

.pedido-color.field[name="estado"] input[value="procesando"]+.button.active {
  background-color: #aadaff;
  color: black;
}

.pedido-color.field[name="estado"] input[value="facturado"]+.button.active {
  background-color: #cec;
  color: black;
}

.pedido-color.field[name="estado"] input[value="cancelado"]+.button.active {
  background-color: #ffbbbb;
  color: black;
}
</style>
<template>
  <div>
    <window-header></window-header>
    <nav>
      <button class="button button-fill" @click="saveWidgets">
        <fa-icon icon="save" />
        <span>{{ $t("rform.save") }}</span>
      </button>
    </nav>
    <section>
      <article
        style="
          height: 300px;
          width: 500px;
          display: flex;
          flex-direction: column;
        "
      >
        <div class="row">
          <div>Disponibles</div>
          <div>Seleccionados</div>
        </div>
        <div class="row" style="flex: 1">
          <Container
            group-name="col"
            class="sortable-block"
            style="width: 50%"
            @drop="onDrop('listInactivo', $event)"
            :get-ghost-parent="getGhostParent"
            :get-child-payload="getChildPayload('listInactivo')"
          >
            <Draggable
              :key="'widget-' + item.id + '-' + i"
              v-for="(item, i) in listInactivo"
            >
              <template v-if="!item.delete">
                <div class="draggable-item card">{{ item.descripcion }}</div>
              </template>
            </Draggable>
          </Container>
          <Container
            group-name="col"
            class="sortable-block"
            style="width: 50%"
            @drop="onDrop('listActivo', $event)"
            :get-ghost-parent="getGhostParent"
            :get-child-payload="getChildPayload('listActivo')"
          >
            <Draggable
              :key="'widget-' + item.id + '-' + i"
              v-for="(item, i) in listActivo"
            >
              <div class="draggable-item card">{{ item.descripcion }}</div>
            </Draggable>
          </Container>
        </div>
      </article>
    </section>
  </div>
</template>
<script>
const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};
import WindowMixin from "./../components/WindowMixin.vue";
import { Container, Draggable } from "vue-smooth-dnd";
export default {
  components: { Container, Draggable },
  mixins: [WindowMixin],
  data: function () {
    return {
      title: "Widgets",
      listActivo: [],
      listInactivo: [],
    };
  },
  mounted() {
    var self = this;
    window.DB.getList("widget", {
      fields: ["descripcion", "activo", "y"],
    }).then((res) => {
      self.listActivo = res.data
        .filter((a) => parseInt(a.activo))
        .sort((a, b) => (parseInt(a.y) > parseInt(b.y) ? 1 : -1));
      self.listInactivo = res.data
        .filter((a) => !parseInt(a.activo))
        .sort((a, b) => (parseInt(a.y) > parseInt(b.y) ? 1 : -1));
    });
  },
  methods: {
    saveWidgets() {
      var self = this;
      let p = Promise.resolve();
      self.listActivo.forEach(function (item, i) {
        p = p.then(
          _ => window.DB.put("widget", {
            itemId: item.id,
            data: { activo: 1 },
          })
        );
      });
      self.listInactivo.forEach(function (item, i) {
        p = p.then(
          _ => window.DB.put("widget", {
            itemId: item.id,
            data: { activo: 0 },
          })
        );
      });
      p.then(function () {
        self.app.toast("Guardado con éxito", "success");

        window.DB.getList("widget", { filter: ["activo", "=", 1] }).then(
          (res) => {
            self.app.widgets = res.data.map((x) => ({
              ...x,
              i: x.id,
              props: x.props ? JSON.parse(x.props) : {},
              h: parseInt(x.h),
              w: parseInt(x.w),
              x: parseInt(x.x),
              y: parseInt(x.y),
            }));
          }
        );
        /*window.DB.getList("widget").then(function (res) {
          self.app.widgets = res.data;
          self.listActivo = self.app.widgets
            .filter((a) => parseInt(a.activo))
            .sort((a, b) => (parseInt(a.y) > parseInt(b.y) ? 1 : -1));
          self.listInactivo = self.app.widgets
            .filter((a) => !parseInt(a.activo))
            .sort((a, b) => (parseInt(a.y) > parseInt(b.y) ? 1 : -1));
        });*/
      }).catch(function () {
        self.app.toast("Ocurrió un error al guardarrrr", "error");
      });
    },
    onDrop: function (listName, dropResult) {
      this[listName] = applyDrag(this[listName], dropResult);
      if (
        dropResult.payload.descripcion == "_line_" &&
        listName == "listInactivo"
      ) {
        dropResult.payload.delete = true;
        //this[listName].splice(dropResult.addedIndex, 1);
      }
    },
    getGhostParent() {
      return document.body;
    },
    getChildPayload(listName) {
      return (index) => {
        return this[listName][index];
      };
    },
  },
};
</script>
<style>
.sortable-block {
  overflow: auto;
}
.main-sortable-block {
  height: 100%;
  display: flex;
}
.draggable-item {
  padding: 5px;
  border: 1px solid #ccc;
  margin: 3px;
  background: white;
}
.row {
  padding: 4px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  overflow: auto;
}
</style>
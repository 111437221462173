//import Handsontable from "handsontable";
var floatOrZero = function (val) {
    return isFinite(parseFloat(val || 0.00)) ? parseFloat(val || 0.00) : 0.00;
};

export default {
    data: function () {
        var self = this;
        return {
            filasPendientesActualizar: [],
            /*tarifa_default: 1,
            margen_default: 0,
            dto_default: 0,
            dtopeso_default: 0,*/
            htSettingsBancos: {},
            fields: ['numero_lineas_pendientes', 'enviar_email_cliente', 'email_cliente', 'wt', 'tipo_impuesto', 'titular', 'cuenta', 'swift', 'ivap', 'rep', { name: 'historial_estados', fields: ['estado', 'create_date'] }],
            beforeSave: function (resolve, reject) {
                var self = this;
                self.actualizar_total_unidades();
                self.actualizar_total_peso();
                self.actualizar_total_articulos();
                self.actualizar_g18k();
                self.actualizar_gmerma();
                self.actualizar_gcontraste_porcentaje();
                self.actualizar_gotroscostes_porcentaje();
                self.actualizar_uotroscostes_porcentaje();
                if (!self.formData.lineas || !self.formData.lineas.length) {
                    reject("Debe introducir alguna línea de pedido");
                } else {
                    resolve(self.formData);
                }
            },
        }
    },
    watch: {
        mode: function (newVal, oldVal) {
            var self = this;
            if ((newVal == "new" || newVal == 'edit') && (self.dbAdapter == 'albaran_cliente' || self.dbAdapter == 'factura_cliente')) {
                self.$set(self.formData, "reglas_taqueria", self.app.config.reglas_taqueria || null);
            }
        }
    },

    methods: {
        /*aplicar_reglas_taqueria() {
            var self = this;
            let h = self.$refs.lineas.field.hotInstance;
            let hrt = self.$refs.reglas_taqueria?.field.hotInstance;
            if (!hrt) return;
            var datos = {}; //Cada clave es un id de taqueria
            h.getSourceData().forEach(function (line) {
                hrt.getSourceData().forEach(function (r) {
                    if (floatOrZero(line.precio) >= r.precio_minimo && floatOrZero(line.precio) <= r.precio_maximo) {
                        let t_ids = typeof r["taqueria_ids"] === "object" ? r["taqueria_ids"].map((el) => el.codigo) : r["taqueria_ids"].split(",");
                        t_ids.forEach(t_id => {
                            datos[t_id] = datos[t_id] || 0;
                            datos[t_id] += floatOrZero(line["unidades"]);
                        })
                    }
                });
            });
            let htData = Object.keys(datos).map((id) => ({
                taqueria_id: id,
                unidades_teoricas: datos[id],
                unidades_reales: datos[id]
            }));
            htData.sort((a, b) => a.codigo > b.codigo ? 1 : a.codigo < b.codigo ? -1 : 0);
            self.$set(self.formData, "taqueria_lineas", htData);
        },*/
        rendererDatetime: function (
            hotInstance,
            td,
            row,
            column,
            prop,
            value,
            cellProperties
        ) {
            if (value) {
                let d = new Date(value);
                value = d.ddmmyyyyhhiiss();
            }
            td.innerText = value;
        },
        mostrarComentariosCliente() {
            let self = this;
            let v = self.formData.cliente_id;
            if (!v.comentarios || !v.comentarios.length) {
                self.app.toast('El cliente no tiene comentarios activos', 'info');
                return;
            }
            var strComentarios = '';
            (v.comentarios || []).forEach(com => {
                if (parseInt(com.activo)) {
                    strComentarios += self.$utils.misc.formatDate(com.fecha) + ': ' + com.descripcion + '<br>';
                }
            });
            if (strComentarios) self.app.toast(strComentarios, 'info');
        },
        abrirSaldos() {
            let v = this.formData.cliente_id;
            this.app.openWindow('cliente', {
                itemId: v.codigo
            });
        },
        actualizar_pvp(row) {
            var self = this;
            let h = self.$refs.lineas.field.hotInstance;
            let val = (columna) => h.getDataAtRowProp(row, columna) || 0;
            var prmetalp = floatOrZero(self.formData.prmetalp || 0);
            var factor = floatOrZero(self.formData.factor || 0);
            var margen = floatOrZero(val('margen'));
            var precio = floatOrZero(val('precio'));
            var dto = floatOrZero(val('descuentos'));
            var peso = floatOrZero(val('peso'));
            var unidades = floatOrZero(val('unidades'));
            var mermaporlinea = parseInt(self.formData.mermaporlinea || 0);
            var merma = mermaporlinea ? floatOrZero(val('mpl')) : floatOrZero(self.formData.merma || 0);
            var contraste = floatOrZero(self.formData.contraste || 0);
            var c2 = parseInt(val('c2'));
            var ocostes = floatOrZero(self.formData.ocostes || 0);
            var p = parseInt(val('p'));
            var ocostesu = floatOrZero(self.formData.ocostesu || 0);
            var u = parseInt(val('u'));
            var m2 = parseInt(val('m2'));

            var precio_venta = (1 + (margen / 100)) * (precio * (1 - dto * 0.01) * (val('v') == 'P' ? peso / unidades : 1) + peso / unidades * (prmetalp * factor * (1 + merma / 100) * m2 + contraste * c2 + ocostes * p) + (ocostesu * u));
            //h.setDataAtRowProp(row, 'pventa', precio_venta, 'auto');
            h.getSourceData()[row].pventa = precio_venta.toFixed(2);
            //h.render();
            self.renderH(h);
        },
        actualizar_margen(row) {
            var self = this;
            let h = self.$refs.lineas.field.hotInstance;
            let val = (columna) => h.getDataAtRowProp(row, columna) || 0;
            var prmetalp = floatOrZero(self.formData.prmetalp || 0);
            var factor = floatOrZero(self.formData.factor || 0);
            var pventa = floatOrZero(val('pventa'));
            var precio = floatOrZero(val('precio'));
            var dto = floatOrZero(val('descuentos'));
            var peso = floatOrZero(val('peso'));
            var unidades = floatOrZero(val('unidades'));
            var mermaporlinea = parseInt(self.formData.mermaporlinea || 0);
            var merma = mermaporlinea ? floatOrZero(val('mpl')) : floatOrZero(self.formData.merma || 0);
            var contraste = floatOrZero(self.formData.contraste || 0);
            var c2 = parseInt(val('c2'));
            var ocostes = floatOrZero(self.formData.ocostes || 0);
            var p = parseInt(val('p'));
            var ocostesu = floatOrZero(self.formData.ocostesu || 0);
            var u = parseInt(val('u'));
            var m2 = parseInt(val('m2'));
            var margen = 100 * ((pventa / (precio * (1 - dto * 0.01) * (val('v') == 'P' ? peso / unidades : 1) + peso / unidades * (prmetalp * factor * (1 + merma / 100) * m2 + contraste * c2 + ocostes * p) + (ocostesu * u))) - 1);
            //if (margen < 0) margen = 0; TODO ???
            //h.setDataAtRowProp(row, 'margen', margen, 'auto');
            if (!isFinite(margen)) margen = 0;
            h.getSourceData()[row].margen = margen.toFixed(2);
            //h.render();
            self.renderH(h);
        },
        actualizar_totallinea(row) {
            var self = this;
            let h = self.$refs.lineas.field.hotInstance;
            let val = (columna) => h.getDataAtRowProp(row, columna) || 0;
            // TODO descuento_peso
            var totallinea = val('precio') * (1 - val('descuentos') * 0.01) * (val('v') == 'P' ? val('peso') : val('unidades'));
            //h.setDataAtRowProp(row, 'totallinea', totallinea, 'auto');
            h.getSourceData()[row].totallinea = totallinea.toFixed(2);
            //h.render();
            self.renderH(h);
            //self.actualizar_total_articulos();
        },
        actualizar_gotroscostes_porcentaje() {
            var self = this;
            let h = self.$refs.lineas.field.hotInstance;
            var arr_o = h.getDataAtProp("p");
            var t = 0;
            h.getDataAtProp("peso").forEach(function (item, i) {
                if (item && parseInt(arr_o[i])) {
                    t += floatOrZero(item);
                }
            });
            self.$set(self.formData, "gotroscostes_porcentaje", t.toFixed(3));
            self.$emit("change", "gotroscostes_porcentaje", "auto");
        },
        actualizar_uotroscostes_porcentaje() {
            var self = this;
            let h = self.$refs.lineas.field.hotInstance;
            var arr_o = h.getDataAtProp("u");
            var t = 0;
            h.getDataAtProp("unidades").forEach(function (item, i) {
                if (item && parseInt(arr_o[i])) {
                    t += floatOrZero(item);
                }
            });
            self.$set(self.formData, "uotroscostes_porcentaje", t.toFixed(3));
            self.$emit("change", "uotroscostes_porcentaje", "auto");
        },
        /*actualizar_total_articulos() {
          let self = this;
          let h = self.$refs.lineas.field.hotInstance;
          var t = 0;
          var t_re = 0;
          var t_sre = 0;
          var re_arr = h.getDataAtProp("r");
          h.getDataAtProp("totallinea").forEach(function (item, i) {
            if (item) {
              t += floatOrZero(item);
              if (parseInt(re_arr[i]) && self.tieneRecargoEquivalencia()) {
                t_re += floatOrZero(item);
              } else {
                t_sre += floatOrZero(item);
              }
            }
          });
          self.$set(self.formData, "total_articulos", t.toFixed(2));
          self.$set(self.formData, "total_articulos_re", t_re.toFixed(2));
          self.$set(self.formData, "total_articulos_sre", t_sre.toFixed(2));
          self.$emit("change", "total_articulos", "auto");
          self.$emit("change", "total_articulos_re", "auto");
          self.$emit("change", "total_articulos_sre", "auto");
        },*/

        tieneRecargoEquivalencia() {
            let self = this;
            return parseInt(self.formData.cliente_id && self.formData.cliente_id.recargo_equivalencia || 0)
        }




    },
    mounted() {
        var self = this;

        //if (self.dbAdapter == 'factura_cliente' || self.dbAdapter == 'albaran_cliente') self.$set(self.formData, "reglas_taqueria", self.app.config.reglas_taqueria || null);

        //Al seleccionar el cliente
        self.$refs.cliente_id.field.$on('select', function () {
            if (self.mode != "new" && self.mode != "edit") return;
            if (!self.formData.cliente_id) return;
            // Cargar datos del cliente
            var v = self.formData.cliente_id;
            let banco = (v.bancos || []).find(el => parseInt(el.predet)) || {};
            self.$set(self.formData, 'titular', banco.titular);
            self.$set(self.formData, 'cuenta', banco.cuenta);
            self.$set(self.formData, 'swift', banco.swift);
            self.$set(self.formData, 'formapago_id', v.forma_pago_id);
            self.$set(self.formData, 'importacion', v.nacionalidad);
            self.$set(self.formData, 'dto_especial_porcentaje', floatOrZero(v.descuento_especial).toFixed(2));
            self.$set(self.formData, 'financiacion_porcentaje', v.financiacion);
            self.$set(self.formData, 'ivap', v.impuesto_id && v.impuesto_id.valor);
            self.$set(self.formData, 'rep', v.recargo_equivalencia ? floatOrZero(self.app.config.recargo_equivalencia) : 0);
            self.$set(self.formData, 'representante_id', v.representantes_ids.length == 1 ? v.representantes_ids[0] : null);
            self.$set(self.formData, 'divisa_id', v.divisa_id);
            self.$set(self.formData, 'cambio', floatOrZero(v.divisa_id.ratio).toFixed(2));
            self.$set(self.formData, 'tipo_impuesto', v.impuesto_id && v.impuesto_id.nombre);
            self.$set(self.formData, 'merma', v.merma);
            self.$set(self.formData, 'mermaporlinea', v.merma_linea);
            self.refrescarColumnaMPL(true);
            let dir = (v.direcciones || []).find(el => parseInt(el.predet)) || {};
            self.$set(self.formData, 'dirc_tipo', dir);
            self.$emit('change', 'formapago_id', 'auto');
            self.$emit('change', 'dto_especial_porcentaje', 'auto');
            self.$emit('change', 'financiacion_porcentaje', 'auto');
            self.$emit('change', 'ivap', 'auto');
            self.$emit('change', 'rep', 'auto');
            self.$emit('change', 'cambio', 'auto');

            if (v.comentarios.length) self.mostrarComentariosCliente();
        });

        //Al seleccionar el pedido
        self.$refs.pedido_id?.field.$on('select', function () {
            if (self.mode != "new" && self.mode != "edit") return;
            if (!self.formData.pedido_id) return;
            // Cargar datos del pedido
            window.DB.get('pedido_cliente', {
                itemId: self.formData.pedido_id.id,
                fields: [
                    { name: 'cliente_id', fields: ['nombre_comercial', { name: 'imagen', fields: ['imagen'] }, 'tarifa', 'descuento_linea_peso', 'descuento_linea_unidad', { name: 'comentarios', fields: ['activo', 'fecha', 'descripcion'] }, 'recargo_equivalencia', 'margen'] },
                    { name: 'tipo_id', fields: ['codigo', 'nombre'] },
                    { name: 'delegacion_id', fields: ['codigo', 'nombre'] },
                    'tipo_metal',
                    { name: 'metal_id', fields: ['codigo', 'descripcion', 'cotizacion', 'factor', 'metal'] },
                    'importacion',
                    { name: 'divisa_id', fields: ['codigo', 'nombre', 'ratio'] },
                    'cambio',
                    'titular',
                    'cuenta',
                    'swift',
                    { name: 'formapago_id', fields: ['codigo', 'descripcion', 'vencimientos', 'periodicidad', 'periodicidad_unidades'] },
                    'dto_especial_porcentaje',
                    'financiacion_porcentaje',
                    'ivap',
                    'rep',
                    { name: 'representante_id', fields: ['codigo', 'imagen', 'nombre', { name: 'almacen', fields: ['codigo', 'nombre'] }] },
                    'tipo_impuesto',
                    'merma',
                    'mermaporlinea',
                    { name: 'dirc_tipo', fields: ['predet', 'descripcion', 'responsable', 'direccion', 'direccion1', 'cp', 'poblacion', { name: 'provincia', fields: ['codigo', 'nombre'] }, { name: 'pais', fields: ['codigo', 'nombre'] }, 'telefono', 'telefono1', 'movil', 'movil1', 'fax'] },
                    'prmetalp',
                    'factor',
                    'contraste',
                    'ocostes',
                    'ocostesu'
                ]
            }).then(data => {
                for (let key in data) {
                    if (['id', 'ndoc'].includes(key)) continue;
                    self.$set(self.formData, key, data[key]);
                }
                window.DB.getList('linea_pedido_cliente', {
                    filter: [['pedido_id', '=', self.formData.pedido_id.id], ['tt', '=', 'R']],
                    fields: [
                        'ean13',
                        {
                            name: 'articulo_id', fields: [
                                'codigo',
                                { name: 'thumbnail', fields: ['imagen'] },
                                { name: 'familia_id', fields: ['codigo', 'nombre'] },
                                'tarifa1',
                                'tarifa2',
                                'tarifa3',
                                'tarifa4',
                                'tarifa5',
                                'tarifa6',
                                'tarifa7',
                                'tarifa8',
                                'tarifa9',
                                'tarifa10'
                            ]
                        },
                        'descripcion',
                        { name: 'modelo_id', fields: ['codigo'] },
                        'nota',
                        'tt',
                        'matricula',
                        { name: 'almacen_id', fields: ['codigo'] },
                        'unidades',
                        'etiqueta',
                        'peso',
                        'v',
                        'm',
                        'r',
                        'tt2',
                        'precio',
                        'descuentos',
                        'descuento_peso',
                        'totalinea',
                        'm2',
                        'c2',
                        'p',
                        'u',
                        { name: 'com', fields: ['codigo'] },
                        'c',
                        'margen',
                        'pventa',
                        'mpl',
                        { name: 'taqueria_ids', fields: ['codigo', { name: 'thumbnail', fields: ['imagen'] }, 'precio_coste'] },
                        'taqueria_precio',
                        'precio_coste',
                        'coste_unidad_venta'
                    ]
                }).then(res => {
                    res.data.forEach(x => delete x.id);
                    self.$set(self.formData, 'lineas', res.data);
                    self.refrescarColumnaMPL(true);
                    self.$emit('change', 'formapago_id', 'auto');
                    self.$emit('change', 'dto_especial_porcentaje', 'auto');
                    self.$emit('change', 'financiacion_porcentaje', 'auto');
                    self.$emit('change', 'ivap', 'auto');
                    self.$emit('change', 'rep', 'auto');
                    self.$emit('change', 'cambio', 'auto');
                })
            })
        });

        // Actualizar taquería al cambiar el calculo_taqueria
        self.onChange(['calculo_taqueria'], function (source) {
            self.actualizar_taqueria();
        });


        //Actualizar precio_coste al cambiar contraste, merma, otroscostes o prmetalp
        self.onChange(['contraste', 'ocostes', 'ocostesu', 'merma', 'prmetalp', 'factor', 'mermaporlinea'], function (source) {
            let h = self.$refs.lineas.field.hotInstance;
            h.getSourceData().forEach(function (item, i) {
                if (item['articulo_id']) self.actualizar_pvp(i); //TODO: optimizar, source=many
            });
        });

        self.$refs.lineas.field.$on("removeRow", function () {
            /*self.actualizar_total_unidades();
            self.actualizar_total_peso();
            self.actualizar_total_articulos();
            self.actualizar_g18k();
            //self.actualizar_g24k_porcentaje(); Lo hace con lo anterior
            self.actualizar_gmerma();
            self.actualizar_taqueria();
            self.actualizar_estadisticas_familias();*/
        });

        var acumulado = '';
        var guardado = {};
        var h = self.$refs.lineas.field.hotInstance;
        let borrarAcumulado = self.$utils.misc.debounce(() => acumulado = '', 50)
        self.$refs.lineas.$el.addEventListener('keydown', function (e) {
            let sel = h.getSelectedLast();
            if (sel && h.colToProp(sel[1]) == 'ean13') {
                borrarAcumulado();
                if (e.key == 'Enter') {
                    let row = sel[0];
                    guardado[row] = acumulado;
                    acumulado = '';
                } else if (e.keyCode > 47 && e.keyCode < 58) {
                    acumulado += e.key;
                }
            }
        });

        self.$refs.lineas.field.hotInstance.addHook('beforeChange', function (changes, source) {
            if (!changes) return;
            changes.forEach(function (change, index) {
                if (!change) return;
                let row = change[0];
                var prop = change[1];
                var newVal = change[3];

                var x = guardado[row];
                if (prop == 'ean13' && newVal && x && x.length > newVal.length) {
                    changes[index][3] = x;
                    guardado[row] = '';
                }
            });
        });


        self.$refs.lineas.field.hotInstance.addHook('afterChange', function (changes, source) {
            if (source == 'loadData') return;
            if (!changes) return;
            var h = this;
            var divisa = floatOrZero(self.formData.cambio);

            //window.console.log(changes, source);
            changes.forEach(function (change, index) {
                if (!change) return;

                var row = change[0];
                var prop = change[1];
                var oldVal = change[2];
                var newVal = change[3];

                //window.console.log(row, prop, source);

                var val = (columna) => h.getDataAtRowProp(row, columna) || 0;
                if (((prop == 'ean13' || prop == 'articulo_id') && newVal && oldVal != newVal && source != 'auto') || source == 'newproduct') {
                    //window.console.log(prop, );
                    //h.selectCell(row + 1, prop);
                    if (prop == 'ean13' && newVal in self.cachedArticulosEan13) {
                        var p = Promise.resolve({
                            count: 1,
                            data: [self.$utils.misc.clone(self.cachedArticulosEan13[newVal])]
                        });
                    } else if (prop == 'articulo_id' && newVal in self.cachedArticulosCodigo) {
                        p = Promise.resolve({
                            count: 1,
                            data: [self.$utils.misc.clone(self.cachedArticulosCodigo[newVal])]
                        });
                    } else {
                        if (prop == 'ean13' && newVal.length == 12) newVal = '0' + newVal;
                        if (prop == 'ean13' && newVal.includes(',')) {
                            newVal = parseInt(newVal.replace('.', '')).toString();
                            newVal = newVal.padStart(7, '0');
                            const ean_checkdigit = (code) => {
                                let sum = 0;
                                for (let i = 0; i < code.length; i++) {
                                    sum += (((i + 1) % 2) * 2 + 1) * code[code.length - 1 - i];
                                }
                                return code + (10 - (sum % 10)) % 10;
                            }
                            newVal = ean_checkdigit(newVal);
                            window.console.log(newVal);
                        }
                        p = window.DB.getList('articulo', {
                            limit: 1,
                            filter: [prop == 'articulo_id' ? 'codigo' : 'ean13', "=", newVal],
                            depth: 3,
                            fields: [
                                'codigo',
                                'ean13',
                                { name: 'modelo_id', fields: ['codigo', 'nombre'] },
                                { name: 'familia_id', fields: ['codigo', 'nombre'] },
                                { name: 'tipo_comision_id', fields: ['codigo', 'descripcion'] },
                                'nombre',
                                'unidad_medida_compra',
                                'compra_metal_incluido',
                                'recargo_equivalencia',
                                'incluir_contraste_pcoste',
                                'incluir_ocostes_pcoste',
                                'precio_coste',
                                'coste_unidad_venta',
                                'unidad_medida_venta',
                                'venta_metal_incluido',
                                'peso_unitario_fino',
                                'margen_ultima_compra',
                                { name: 'taqueria_ids', fields: ['codigo', 'nombre', { name: 'thumbnail', fields: ['imagen'] }, 'precio_coste', 'coste_unidad_venta'] },
                                'taqueria_precio',
                                { name: 'thumbnail', fields: ['imagen'] },
                                'tarifa1',
                                'tarifa2',
                                'tarifa3',
                                'tarifa4',
                                'tarifa5',
                                'tarifa6',
                                'tarifa7',
                                'tarifa8',
                                'tarifa9',
                                'tarifa10'
                            ]
                        });
                    }
                    p.then(function (res) {
                        if (parseInt(res.count)) {
                            let a = res.data[0];
                            self.cachedArticulosCodigo[a.codigo] = a;
                            self.cachedArticulosEan13[a.ean13] = a;

                            let d = h.getSourceData()[row];
                            d.articulo_id = a;
                            d.ean13 = a.ean13;
                            d.modelo_id = a.modelo_id;
                            //d.familia_id = a.familia_id.codigo;
                            d.descripcion = a.nombre;
                            d.almacen_id = self.app.config.almacen_defecto || null;
                            d.nota = '';
                            d.tt = 'A';
                            d.unidades = 1;
                            d.etiqueta = 1;
                            d.peso = a.peso_unitario_fino || 0;
                            d.mpl = (self.formData.merma || 0);
                            d.taqueria_ids = a.taqueria_ids;
                            d.taqueria_precio = a.taqueria_precio;
                            d.v = a.unidad_medida_venta == 'peso' ? 'P' : 'U';
                            d.m = parseInt(a.venta_metal_incluido) ? 1 : 0;
                            d.r = a.recargo_equivalencia || 0;
                            d.tt2 = self.formData.cliente_id.tarifa || 1;
                            d.precio = floatOrZero(a['tarifa' + (self.formData.cliente_id.tarifa ? self.formData.cliente_id.tarifa : 1)] * divisa).toFixed(2);
                            d.descuentos = floatOrZero(d.v == 'P' ? self.formData.cliente_id.descuento_linea_peso : self.formData.cliente_id.descuento_linea_unidad).toFixed(2);
                            //d.descuento_peso = floatOrZero(self.formData.cliente_id.descuento_linea_peso).toFixed(2);
                            d.m2 = (parseInt(a.venta_metal_incluido) ? 0 : 1);
                            d.c2 = a.incluir_contraste_pcoste;
                            d.p = 0;
                            d.u = 0;
                            d.com = a.tipo_comision_id;
                            d.c = 'D'; //Comisión
                            d.margen = floatOrZero(self.formData.cliente_id.margen).toFixed(2);
                            //d.pventa = a.pventa;//PVP
                            d.e = 0;
                            d.precio_coste = a.coste_unidad_venta;

                            if (source == 'CopyPaste.paste') {
                                changes.forEach(function (change, index) {
                                    if (!change) return;
                                    let prop = change[1];
                                    let row2 = change[0];
                                    let newVal = change[3];
                                    if (prop != 'ean13' && prop != 'articulo_id' && newVal && row == row2) {
                                        d[prop] = newVal;
                                    }
                                });
                            }

                            //h.render();
                            self.renderH(h);
                            //TODO? trigger afterChange rHandsontable?
                            self.filasPendientesActualizar.push(row);
                            self.actualizarTodo();


                            if (parseInt(self.app.config['mostrar_imagen_' + self.dbAdapter])) {
                                if (self.win.children.length && self.win.children.some(ch => ch.window_name == 'popup_imagen')) {
                                    let popupImagen = self.win.children.find(ch => ch.window_name == 'popup_imagen');
                                    if (popupImagen.instance) popupImagen.instance.articulo = a;
                                    else popupImagen.opts.articulo = a;
                                } else {
                                    let p = self.interactPosition;
                                    let $el = self.win.instance.$el;
                                    self.app.openChildWindow('popup_imagen', self.win, {
                                        backdrop: false,
                                        resizable: true,
                                        articulo: a,
                                        x: $el.offsetWidth + p.x,
                                        y: p.y
                                    })
                                }
                            }
                        } else {
                            self.app.toast('No se han encontrado el artículo', 'error');
                            h.setDataAtRowProp(row, prop, oldVal, 'auto');
                            //h.selectCell(row, prop);
                        }
                    }).catch(function (res) {
                        window.console.log(res);
                    });
                }

                //Calcular Margen y Pventa=PVP
                if (['v'].includes(prop) && oldVal != newVal) {
                    h.getSourceData()[row].descuentos = newVal == 'P' ? self.formData.cliente_id.descuento_linea_peso : self.formData.cliente_id.descuento_linea_unidad;
                }
                if (['precio', 'descuentos', 'v', 'peso', 'unidades'].includes(prop) && oldVal != newVal) {
                    self.actualizar_totallinea(row);
                    self.actualizar_pvp(row);
                }
                if (['margen', 'mpl', 'c2', 'p', 'u', 'm2'].includes(prop) && oldVal != newVal) {
                    self.actualizar_pvp(row);
                }
                if (['pventa'].includes(prop) && oldVal != newVal) {
                    self.actualizar_margen(row);
                }

                // Tipo almacen
                if (prop == 'tt' && oldVal != newVal) {
                    if (newVal == 'R') {
                        h.getSourceData()[row].almacen_id = self.formData.representante_id && self.formData.representante_id.almacen;
                    } else {
                        h.getSourceData()[row].almacen_id = self.app.config.almacen_defecto;
                    }
                }

                //Actualizar precio según tarifa
                if (prop == 'tt2' && oldVal != newVal) {

                    let articulo_id = h.getDataAtRowProp(row, 'articulo_id');
                    if (articulo_id) {
                        let arti = (typeof articulo_id == "object" ? articulo_id : h.cachedItems['articulo_id']?.[articulo_id]) || {};
                        let tarifa = floatOrZero(arti['tarifa' + newVal] * divisa);
                        if (!isFinite(tarifa)) tarifa = 0;
                        //h.setDataAtRowProp(row, 'precio', tarifa, 'auto');
                        h.getSourceData()[row].precio = tarifa;
                        self.actualizar_totallinea(row);
                        self.actualizar_pvp(row);
                        // self.actualizar_estadisticas_familias();
                    }
                }
                //Unidades: 1, Etiq: 1 y recalcular peso
                if (prop == 'unidades' && oldVal != newVal) {
                    let etiqueta = Math.ceil(newVal);
                    //h.setDataAtRowProp(row, 'etiqueta', etiqueta, 'auto');
                    h.getSourceData()[row].etiqueta = etiqueta;
                    if (oldVal) {
                        var peso = newVal * val('peso') / oldVal;
                    } else {
                        peso = 0;
                    }
                    //h.setDataAtRowProp(row, 'peso', peso, 'auto');
                    h.getSourceData()[row].peso = peso;
                    self.actualizar_totallinea(row);
                    self.actualizar_pvp(row);
                    // self.actualizar_total_peso();
                    // self.actualizar_g18k();
                    // self.actualizar_gmerma();
                }
                //Al marcar el campo Metal en venta
                if (prop == 'm' && oldVal != newVal) {
                    if (newVal == 1) {
                        h.getSourceData()[row].m2 = 0;
                        h.getSourceData()[row].c2 = 0;
                        //h.setDataAtRowProp(row, 'm2', 0, 'auto');
                        //h.setDataAtRowProp(row, 'c2', 0, 'auto');
                    } else {
                        h.getSourceData()[row].m2 = 1;
                        h.getSourceData()[row].c2 = 1;
                        //h.setDataAtRowProp(row, 'm2', 1, 'auto');
                        //h.setDataAtRowProp(row, 'c2', 1, 'auto');
                    }
                    self.actualizar_pvp(row);
                }
                if (prop == 'm2' && oldVal != newVal && source != 'auto') {
                    if (newVal == 1) {
                        h.getSourceData()[row].m = 0;
                        //h.setDataAtRowProp(row, 'm', 0, 'auto');
                    } else {
                        h.getSourceData()[row].m = 1;
                        h.getSourceData()[row].c2 = 0;
                        //h.setDataAtRowProp(row, 'm', 1, 'auto');
                        //h.setDataAtRowProp(row, 'c2', 0, 'auto');
                    }
                    self.actualizar_pvp(row);
                }

                //Si cambiamos el totallinea, cambiamos el descuento
                if (prop == 'totallinea' && oldVal != newVal && source != 'auto') {
                    if (val('v') == 'P') {
                        var descuento = -(val('totallinea') / (val('precio') * val('peso')) - 1) * 100;
                    } else {
                        descuento = -(val('totallinea') / (val('precio') * val('unidades')) - 1) * 100;
                    }
                    h.getSourceData()[row].descuentos = descuento;
                    //h.render();
                    self.renderH(h);
                    //h.setDataAtRowProp(row, 'descuentos', descuento, 'auto');
                }

                if ((prop == 'taqueria_ids') && oldVal != newVal) {
                    self.actualizar_taqueria_precio(row);
                }

                //Cálculos en el PIE
                /*if (prop == 'unidades' && oldVal != newVal) {
                     self.actualizar_total_unidades();
                }
                if (prop == 'peso' && oldVal != newVal) {
                     self.actualizar_total_peso();
                }
                //if (prop == 'totallinea' && oldVal != newVal) {
                //    self.actualizar_total_articulos();
                //}
                if ((prop == 'totallinea' || prop == 'r') && oldVal != newVal) {
                     self.actualizar_total_articulos();
                }
                if ((prop == 'peso' || prop == 'm2') && oldVal != newVal) {
                     self.actualizar_g18k();
                     self.actualizar_gmerma();
                }
                if ((prop == 'mpl') && oldVal != newVal) {
                     self.actualizar_gmerma();
                }
                if (['familia_id', 'precio', 'unidades'].includes(prop)) {
                     self.actualizar_estadisticas_familias();
                }

                if ((prop == 'taqueria_ids' || prop == 'unidades') && oldVal != newVal) {
                     self.actualizar_taqueria();
                }
                */
            });
        });

        self.$refs.lineas.field.hotInstance.addHook('afterSelectionEnd', function (row) {
            if (parseInt(self.app.config['mostrar_imagen_' + self.dbAdapter])) {
                let h = this;
                let articulo_id = h.getSourceData()[row].articulo_id;
                if (!articulo_id) return;
                if (typeof articulo_id == 'object') var a = articulo_id;
                else a = h.cachedItems.articulo_id[articulo_id] || {};

                if (self.win.children.length && self.win.children.some(ch => ch.window_name == 'popup_imagen')) {
                    let popupImagen = self.win.children.find(ch => ch.window_name == 'popup_imagen');
                    if (popupImagen.instance) popupImagen.instance.articulo = a;
                    else popupImagen.opts.articulo = a;
                } else {
                    let p = self.interactPosition;
                    let $el = self.win.instance.$el;
                    self.app.openChildWindow('popup_imagen', self.win, {
                        backdrop: false,
                        resizable: true,
                        articulo: a,
                        x: $el.offsetWidth + p.x,
                        y: p.y
                    })
                }
            }
        });

        self.$refs.lineas.field.hotInstance.updateSettings({
            enterMoves: function (event) {
                let h = self.$refs.lineas.field.hotInstance;
                let sel = h.getSelectedLast();
                let prop = h.colToProp(sel[1]);
                let value = h.getSourceData()[sel[0]][prop];
                if ((prop == "ean13" || prop == "articulo_id") && value) {
                    return { row: 1, col: 0 };
                }
                return { row: 0, col: 1 };
            }
        });

        self.actualizarTodo = self.$utils.misc.debounce(function (allRows = false) {
            if (allRows && self.formData.lineas) {
                self.formData.lineas.forEach(function (linea, row) {
                    self.actualizar_totallinea(row);
                    self.actualizar_taqueria_precio(row);
                    self.actualizar_pvp(row);
                });
            } else {
                self.filasPendientesActualizar.forEach(function (row) {
                    self.actualizar_totallinea(row);
                    self.actualizar_taqueria_precio(row);
                    self.actualizar_pvp(row);
                });
            }
            self.filasPendientesActualizar = [];

            /*self.actualizar_total_unidades();
            self.actualizar_total_peso();
            self.actualizar_total_articulos();
            self.actualizar_g18k();
            //self.actualizar_g24k_porcentaje(); //Lo hace con lo anterior
            self.actualizar_gmerma();
            self.actualizar_taqueria();
            self.actualizar_estadisticas_familias();*/
        }, 500);

        self.$on('loadItem', function () {
            //self.actualizar_taqueria();
            self.actualizar_estadisticas_familias();
            self.$set(self.formData, "reglas_taqueria", self.app.config.reglas_taqueria || null);
            if (self.$refs.pedido_id) self.actualizar_discrepancias();
        });

        /*window.DB.getList('regla_taqueria', {
            fields: ['precio_minimo', 'precio_maximo', { name: 'taqueria_ids', fields: ['codigo', 'nombre', { name: 'thumbnail', fields: ['imagen'] }] }],
            depth: 3,
            order: 'id',
            orderDir: 'ASC'
        }).then(res => {
            self.$set(self.formData, "reglas_taqueria", res.data);
        });*/


    }
}